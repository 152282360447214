import React, { Component } from 'react';
import Terms from '../singlePages/Terms';
import Privacy from '../singlePages/Privacy';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// display different modals and info boxes
class ModalInfo extends Component {
  constructor(props) {
    super(props);
    this.closeModal1 = this.closeModal1.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
  }
  closeModal1() {
    this.props.appActions.setModalInfo('none');
  }
  closeModal2() {
    let valid = Object.assign({}, this.props.valid);
    valid['finished'] = 'error';
    this.props.appActions.setValidation(valid);
  }

  render() {
    // if (this.props.modal === 'message2') {
    //   setTimeout(() => this.closeModal1(), 10000);
    // }
    return (
      <div>
        {/* MODAL 1 */}
        <Modal show={this.props.modal === 'privacy'}>
          <Modal.Body>
            <br />
            <Privacy {...this.props} />
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={this.props.modal === 'terms'}>
          <Modal.Body>
            <br />
            <Terms {...this.props} />
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={this.props.modal === 'message1'} className="modalMsg1">
          <Modal.Body className="text-center">
            <img alt="logo" className="mW220" src="/images/logo.png" />
            <br />
            <h2 className="text-center">
              {this.props.t('OR_SEARCH')}
              {' ... ?'}
            </h2>
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={this.props.modal === 'message3'} className="modalMsg1">
          <Modal.Body className="text-center">
            <img alt="logo" className="mW220" src="/images/logo.png" />
            <br />
            <h2 className="text-center">{this.props.t('ROOM_ERROR')}</h2>
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={this.props.modal === 'message5'} className="modalMsg1">
          <Modal.Body className="text-center">
            <img alt="logo" className="mW220" src="/images/logo.png" />
            <br />
            <h3 className="text-center">{this.props.t('HOTEL_NOT_FOUND')}</h3>
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        {/* MODAL 2 */}
        <Modal show={this.props.valid && this.props.valid.finished === 'open'}>
          <Modal.Body className="text-center">
            <img alt="logo" className="mW220" src="/images/logo.png" />
            <br />
            <h2 className="text-center txt-error">
              {this.props.t('PLZ_CHECK_ENTRIES')}
            </h2>
            <br />
            <button onClick={() => this.closeModal2()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        {/* MODAL 3 */}
        <Modal show={this.props.modal === 'message4'}>
          <Modal.Body className="text-center">
            <img alt="logo" className="mW220" src="/images/logo.png" />
            <br />
            <h2 className="text-center txt-error">
              {this.props.t('PLZ_CHECK_SEARCH')}
            </h2>
            <br />
            <button onClick={() => this.closeModal1()}>
              {this.props.t('CLOSE_TEXT')}
            </button>
          </Modal.Body>
        </Modal>
        {/* MESSAGE 3 */}
        <Alert
          variant="danger"
          className="margin-b20px"
          hidden={this.props.modal !== 'message2'}
        >
          <div className="text-center position-rel">
            <br />
            <h2 className="text-center txt-error">
              {this.props.t('ERROR_DATE')}
            </h2>
            <br />
            <button
              className="btn-transparent-red right-top-abs"
              onClick={() => this.closeModal1()}
            >
              {/* <Glyphicon glyph="remove" /> */}
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </Alert>
      </div>
    );
  }
}

export default ModalInfo;
