/* global google */
import React from 'react';
// import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { scroller } from 'react-scroll';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    // this.getEnglishName = this.getEnglishName.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = city => {
    this.props.appActions.setCity(city);
    // if (this.props.cityEn !== '') this.props.appActions.setCityEn('');
  };

  // getEnglishName(city) {
  //   axios
  //     .post('https://email.hotelshop.com/googleplacestextquery', {
  //       inputQuery: city,
  //       language: 'en'
  //     })
  //     .catch(err => {
  //       this.props.appActions.setCityEn(city);
  //     })
  //     .then(response => {
  //       if (response && response.status === 200) {
  //         let res1 = JSON.parse(response.data);
  //         if (res1.status === 'OK') {
  //           this.props.appActions.setCityEn(
  //             res1.candidates[0].formatted_address
  //           );
  //         } else {
  //           this.props.appActions.setCityEn(city);
  //         }
  //       }
  //     });
  // }

  handleSelect = city => {
    geocodeByAddress(city)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.appActions.setLatLng(latLng.lat, latLng.lng, city);
        // this.getEnglishName(city);
        // console.log(latLng.lat, latLng.lng);
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    let loc =
      this.props.userInfo && this.props.userInfo.hasOwnProperty('location')
        ? this.props.userInfo.location
        : null;
    let rdd =
      this.props.userInfo && this.props.userInfo.hasOwnProperty('location')
        ? 1000000
        : null;
    let searchOpt = {
      location: loc
        ? new google.maps.LatLng(loc.latitude, loc.longitude)
        : null,
      radius: loc ? rdd : null,
      types: ['(cities)']
    };
    return (
      <PlacesAutocomplete
        value={this.props.city}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOpt}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: this.props.t('OR_SEARCH') + ' ...',
                className: 'location-search--input rad0 txt18'
              })}
              onClick={() => {
                scroller.scrollTo('autocomplete', {
                  duration: 500,
                  smooth: true
                });
              }}
              onFocus={() => this.props.appActions.setLatLng(0, 0, '')}
            />
            {/* {this.props.city && this.props.city.length > 0 && (
              <Button
                className="btn-remove float-right z-index10000"
                onClick={() => {
                  this.props.appActions.setLatLng(0, 0, '');
                  // this.props.appActions.setCityEn('');
                }}
              >
                <FontAwesomeIcon className="" icon={faTimes} />
              </Button>
            )} */}
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    key={index + Math.round(Math.random() * 1000)}
                    {...getSuggestionItemProps(suggestion, { className })}
                  >
                    <span>
                      {/* <Glyphicon glyph="map-marker" className=" txt-blue-1" />{' '} */}
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className=" txt-blue-1"
                      />{' '}
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default Autocomplete;
