import React, { Component } from 'react';
import { MAX_GUESTS } from '../common/const';
import { listHelper } from '../common/utils';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMale,
  faChild,
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons';

// choose number of guests and
// number of rooms for the search
class NumberRooms extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showRoom: 0,
      childAgeOpen: -1,
      open: false
    };
  }

  componentWillMount() {
    if (this.props.rooms && !this.props.rooms[0][2])
      this.props.appActions.setRooms([[1, 0, []]]);
  }

  handleSelect = activeKey => {
    if (!activeKey) activeKey = -1;
    this.props.appActions.showRooms(this.props.showRooms, activeKey.toString());
  };

  addRoom = () => {
    let roomstemp = this.props.rooms.slice(0);
    roomstemp.push([1, 0, []]);
    this.props.appActions.setRooms(roomstemp);
    this.handleSelect((roomstemp.length - 1).toString());
  };

  delRoom = num => {
    if (this.props.rooms.length > 1) {
      let roomstemp = this.props.rooms.slice(0);
      roomstemp.splice(num, 1);
      this.props.appActions.setRooms(roomstemp);
      this.handleSelect((roomstemp.length - 1).toString());
    }
    this.setState({ childAgeOpen: -1 });
  };

  totalGuests = type => {
    let temp = 0;
    if (type === 'all') {
      for (let i = 0; i < this.props.rooms.length; i++) {
        temp = temp + this.props.rooms[i][0] + this.props.rooms[i][1];
      }
    } else if (type === 'a') {
      for (let i = 0; i < this.props.rooms.length; i++) {
        temp = temp + this.props.rooms[i][0];
      }
    } else if (type === 'c') {
      for (let i = 0; i < this.props.rooms.length; i++) {
        temp = temp + this.props.rooms[i][1];
      }
    }
    return temp;
  };

  calcPeople = (p, num, i, op) => {
    if (num <= MAX_GUESTS) {
      let roomstemp = this.props.rooms.slice(0);
      if (op === 'add' && num < MAX_GUESTS) {
        ++num;
        if (p === 2) roomstemp[i][2].push(1);
      } else if (
        op === 'sub' &&
        ((p === 1 && num > 1) || (p === 2 && num > 0))
      ) {
        --num;
        if (p === 2) roomstemp[i][2].pop();
      }
      if (p === 1) {
        roomstemp[i] = [num, this.props.rooms[i][1], this.props.rooms[i][2]];
        this.props.appActions.setRooms(roomstemp);
      } else if (p === 2) {
        roomstemp[i] = [this.props.rooms[i][0], num, this.props.rooms[i][2]];
        this.props.appActions.setRooms(roomstemp);
      }
    }
  };

  changeChildAge = (i, index, value) => {
    this.setState({ childAgeOpen: -1 });
    let roomstemp = this.props.rooms.slice(0);
    roomstemp[i][2][index] = value;
    this.props.appActions.setRooms(roomstemp);
  };

  toggleDropdown = () => {
    this.props.appActions.showRooms(
      !this.props.showRooms,
      this.props.activeRoom
    );
  };

  render() {
    // console.log(this.props.rooms)
    let adultsChildren = (
      <span>
        <span>{this.props.t('ADULTS').toLowerCase()}</span>
        :&nbsp;
        <span className="font-large font-light">{this.totalGuests('a')}</span>
        ,&nbsp;
        <span>{this.props.t('SMALL_CHILDRENS').toLowerCase()}</span>
        :&nbsp;
        <span className="font-large font-light">{this.totalGuests('c')}</span>
        &nbsp;&nbsp;&nbsp;
      </span>
    );
    const { t, i18n } = this.props;
    return (
      <div className="dropdown1">
        <Dropdown>
          <Dropdown.Toggle
            variant="none"
            drop="down"
            className="btn-room-people"
            id="dropdown-room"
          >
            {adultsChildren}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Accordion className="p-2" defaultActiveKey="0">
              {this.props.rooms.map((item, i) => (
                <Card key={i}>
                  <div
                    className="panel-height back-color-gray1"
                    as={Card.Header}
                    onClick={() => this.setState({ showRoom: i })}
                    eventKey={i.toString()}
                  >
                    {/* <Card.Title> */}
                    <h4 className="d-inline float-left padding-10">
                      {t('ROOM')} {i + 1}
                    </h4>
                    {this.props.rooms.length > 1 && (
                      <button
                        className="btn-remove float-right"
                        onClick={() => this.delRoom(i)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="btn-remove"
                        />
                      </button>
                    )}
                    {/* </Card.Title>             */}
                  </div>
                  {this.state.showRoom === i && (
                    <div>
                      <Card.Body>
                        <div className="rooms-item-holder margin-lr-auto clear-both">
                          <span className="float-left text-left rooms-label">
                            {/* {t('ADULTS')} */}
                            <FontAwesomeIcon
                              className="font-very-large txt-gray-blue-light male-room-holder"
                              icon={faMale}
                            />
                          </span>
                          <span className="float-right text-left">
                            <button
                              className="btn-plus-min float-right"
                              onClick={() =>
                                this.calcPeople(1, item[0], i, 'add')
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <div className="display-num">{item[0]}</div>
                            <button
                              className="btn-plus-min float-right"
                              onClick={() =>
                                this.calcPeople(1, item[0], i, 'sub')
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </button>
                          </span>
                        </div>
                        <div className="rooms-item-holder margin-lr-auto">
                          <span className="float-left text-left rooms-label">
                            <FontAwesomeIcon
                              className="fa-2x txt-gray-blue-light"
                              icon={faChild}
                            />
                            {/* {t('SMALL_CHILDRENS')} */}
                          </span>
                          <span className="float-right text-left">
                            <button
                              className="btn-plus-min float-right"
                              onClick={() =>
                                this.calcPeople(2, item[1], i, 'add')
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <div className="display-num">{item[1]}</div>
                            <button
                              className="btn-plus-min float-right"
                              onClick={() =>
                                this.calcPeople(2, item[1], i, 'sub')
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </button>
                          </span>
                        </div>
                        <div className="margin-t10">
                          {item[2] &&
                            item[2].map((child, index) => (
                              <div key={index}>
                                <Button
                                  variant="none"
                                  className="btn-room-people padding0 margin0"
                                  onClick={() =>
                                    this.setState({
                                      childAgeOpen:
                                        this.state.childAgeOpen !== index
                                          ? index
                                          : -1
                                    })
                                  }
                                >
                                  {
                                    <span>
                                      Child age:{' '}
                                      <strong>
                                        {this.props.rooms[i][2][index]}
                                      </strong>
                                    </span>
                                  }
                                </Button>
                                {this.state.childAgeOpen === index && (
                                  <Row>
                                    {listHelper(18).map((age, ind) => (
                                      <Col
                                        xs={3}
                                        sm={2}
                                        md={4}
                                        xl={3}
                                        key={ind}
                                        className="padding-5"
                                      >
                                        <button
                                          className={
                                            this.props.rooms[i][2][index] ===
                                            age
                                              ? 'btn-plus-min'
                                              : 'btn-round-1'
                                          }
                                          onClick={() =>
                                            this.changeChildAge(i, index, age)
                                          }
                                        >
                                          {age}
                                        </button>
                                      </Col>
                                    ))}
                                  </Row>
                                )}
                              </div>
                            ))}
                        </div>
                        <div className="clear-all"></div>
                      </Card.Body>
                    </div>
                  )}
                </Card>
              ))}
              {this.props.rooms.length < 4 && (
                <button
                  className="width-100 padding-10"
                  onClick={() => this.addRoom()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              )}
            </Accordion>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default NumberRooms;
