import React, { Component } from 'react';
import SocialButtons from './SocialButtons';
import { Grid, Col, Nav, Container, Row } from 'react-bootstrap';
// import { Parallax } from 'react-parallax';
// import axios from 'axios';
// import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import PartnerIcons from './PartnerIcons';
import Subscribe from './Subscribe';

// import EmailConfirmation from './EmailConfirmation';

class Footer extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.sendEmail = this.sendEmail.bind(this);
  // }
  // sendEmail() {
  // let bookId = '1234';
  // let test={
  //   bookId: 1234,
  //   bookInfo: [{text:'darko', newtext:'hotel', onemore:'going'},{text:'darko', newtext:'hotel', onemore:'going'}]
  // }
  // let emailSuccess = false;
  // let nameFrom = 'Hotelshop.com <support@hotelshop.com>';
  // let emailTo = 'darko@sky-tours.com';
  // let emailCc = '';
  // let emailBcc = '';
  // // const emailBcc = 'tickets@sky-tours.com';
  // let emailSubject = 'your order';
  // let emailMessageTxt = '';
  // let emailMessageHtml = '';
  // let confirmationId = window.location.search.slice(
  //   10,
  //   window.location.search.length
  // );
  // let data = {
  //   nameFrom: nameFrom,
  //   emailTo: emailTo,
  //   emailCc: emailCc,
  //   emailBcc: emailBcc,
  //   emailSubject: emailSubject,
  //   emailMessageTxt: emailMessageTxt,
  //   emailMessageHtml: emailMessageHtml,
  //   emailConfirmId: confirmationId
  // };
  // axios.post('http://www.hotelshop.com:3003/writebookinfo', test).then(response => {
  //   if (response.data.msg === 'success') {
  //     // this.props.appActions.setRouteInfo('/order/id=' + confirmationId);
  //     // emailSuccess = true;
  //   } else if (response.data.msg === 'fail') {
  //     // this.props.appActions.setRouteInfo('/order/id=' + confirmationId);
  //     // emailSuccess = true;
  //   }
  // });

  //   this.props.appActions.setRouteInfo('home')
  //   axios.get('http://localhost:8080/readbookinfo/'+bookId).then(response => {

  //     let test = JSON.parse(response.data.bookInfo);
  //     console.log('test', test, test[0].newtext)
  //   });
  // }

  render() {
    const { t, i18n } = this.props;
    return (
      <div className="margin-t20">
        {this.props.subStatus && this.props.subStatus !== 'show' && (
          <div>
            <Container>
              <Col xs={12} className="text-left">
                <h3 className="padding0 margin-t0 txt-blue">
                  Subscribe for the best hotel deals!
                </h3>
              </Col>
            </Container>
            <Col className="back-blue padding-tb15">
              <Container className="padding0">
                <Subscribe {...this.props} />
              </Container>
            </Col>
          </div>
        )}
        <footer>
          {/* <EmailConfirmation {...this.props}/> */}
          <div className="container">
            <Row>
              <Col md={4} className="margin-t10">
                <a href="/">
                  <img alt="logo" className="logo" src="/images/logo.png" />
                </a>
                <br />
                <span
                  className="txt14px link-1"
                  dangerouslySetInnerHTML={{ __html: t('ABOUT_US_TEXT') }}
                />
              </Col>
              {this.props.lang && (
                <Col md={3} className="footer-links">
                  <h3 className="margin-tb15">{t('USEFUL_LINKS')}</h3>
                  <Link
                    to={'/' + this.props.lang.value + '/info/about'}
                    onClick={() => scroll.scrollToTop()}
                  >
                    {t('ABOUT_US')}
                  </Link>
                  <br />
                  <Link
                    to={'/' + this.props.lang.value + '/info/terms'}
                    onClick={() => scroll.scrollToTop()}
                  >
                    {t('TERMS_CONDITIONS')}
                  </Link>
                  <br />
                  <Link
                    to={'/' + this.props.lang.value + '/info/privacy'}
                    onClick={() => scroll.scrollToTop()}
                  >
                    {t('PRIVACY')}
                  </Link>
                  {/* <br />
                <div className="txt-white" onClick={() => this.sendEmail()}>
                  Press
                </div> */}
                </Col>
              )}
              <Col md={5} className="social-btns footer-links">
                <Row>
                  <Col md={12}>
                    <Element name="footerContact">
                      <h3 className="margin-tb15">{t('CONTACT_US')}</h3>
                      <a href={'tel:' + t('PHONE_NR')}>{t('PHONE_NR')}</a>
                      <br />
                      <a href="mailto:support@hotelshop.com">
                        support@hotelshop.com
                      </a>
                      <br />
                      {/* <h3 className="margin-tb15">{t('FOLLOW_US')}</h3>
                    <SocialButtons /> */}
                    </Element>
                  </Col>
                  <Col md={12} className="social-btns footer-links">
                    {/* <h3 className="margin-tb15">{t('FOLLOW_US')}</h3> */}
                    <SocialButtons />
                  </Col>
                </Row>
                {/* <Col md={2} className="footer-links" /> */}
                <Row></Row>
              </Col>
            </Row>
            <Col md={12} align="middle" className="text-center">
              <hr className="width-100-pcent" />
              <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                <h4 className="text-uppercase">{t('OUR_PARTNERS')}</h4>
                <PartnerIcons />
              </ScrollAnimation>
            </Col>
            <Col md={12} align="middle" className="text-left">
              <hr className="width-100-pcent" />
              <span className="txt-gray-blue txt14px">
                {t('ALL_RIGHTS_RESERVED')}
              </span>
            </Col>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
