import React, { Component } from 'react';
import Languages from './Languages';
import SocialButtons from './SocialButtons';
// import FavoritesList from './FavoritesList';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class TopBar extends Component {
  render() {
    return (
      <div className="top-bar">
        <Container>
          <Row>
            <Col className="text-left d-none d-md-block">
              <SocialButtons {...this.props} white={true} />
            </Col>
            <Col className="text-right">
              {this.props.lang && this.props.curr && this.props.country && (
                <Languages {...this.props} />
              )}
            </Col>
          </Row>
        </Container>
        {/* {this.props.favs && this.props.favs.length > 0 && (
            <Col className="pull-right">
              <FavoritesList {...this.props} />
            </Col>
          )} */}
      </div>
    );
  }
}

export default TopBar;
