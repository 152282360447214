import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/allReducers';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
export const history = createHistory();
const persistConfig = {
  key: 'root',
  storage: storage,
  // stateReconciler: hardSet,
  blacklist: ['routing', 'appRedOrder']
};

// const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  // applyMiddleware(logger),
  ...enhancers
);

export default () => {
  let store = createStore(
    persistReducer(persistConfig, rootReducer),
    composedEnhancers
  );

  let persistor = persistStore(store);
  return { store, persistor };
};
