import React from 'react';
import { PARTNERS } from '../common/const';

const PartnerIcons = () => {
  return PARTNERS.map(partner => (
    <span key={partner}>
      <img
        src={'/images/partners/' + partner + '.png'}
        alt={partner}
        className={partner}
      />
    </span>
  ));
};

export default PartnerIcons;
