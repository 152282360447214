import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'react-router-redux';
import configureStore from './store/configureStore';
import App from './App';
import i18n from './i18n';
// import { PersistGate } from 'redux-persist/integration/react';
// import registerServiceWorker from './registerServiceWorker';
import HttpsRedirect from 'react-https-redirect';
let { store, persistor } = configureStore();
const target = document.querySelector('#root');
render(
  <Provider store={store}>
    <HttpsRedirect>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <div>
        <App />
      </div>
      {/* </PersistGate> */}
    </HttpsRedirect>
  </Provider>,
  target
);
// registerServiceWorker();
