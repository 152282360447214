import React, { Component } from 'react';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

class NationailtySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryPhoneData: [],
      toggleButton: false
    };
    this.isUnmounted = false;
  }

  componentWillMount() {
    let telPrefix = '';
    axios
      .get('/data/countryPhoneData.json')
      .catch(err => {})
      .then(response => {
        if (!this.isUnmounted) telPrefix = sortBy(response.data, ['name']);
        // console.log(response.data);
        this.setState({ countryPhoneData: telPrefix });
      });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  changeValue = e => {
    let temp = this.state.countryPhoneData.filter(obj => {
      return obj.name === e.target.value;
    });
    temp &&
      temp[0] &&
      this.props.appActions.changeCountry({
        code: temp[0].code,
        name: temp[0].name
      });
  };

  changeValueDrop = val => {
    let temp = this.state.countryPhoneData.filter(obj => {
      return obj.name === val;
    });
    temp &&
      temp[0] &&
      this.props.appActions.changeCountry({
        code: temp[0].code,
        name: temp[0].name
      });
  };

  toggleDropdown = () => {
    this.setState({ toggleButton: !this.state.toggleButton });
  };

  render() {
    return !this.props.selectDropdown ? (
      <Form.Group>
        <Form.Label className="float-left">
          <div className="width-100 txt-blue">
            <h3 className="padding-5 text-left">
              <div className="border-blue-left">
                <div>{this.props.t('NATIONALITY')}</div>
                <span className="txt18 txt-gray-blue">
                  {this.props.country.name}
                </span>
                <div className="clear-all" />
              </div>
            </h3>
          </div>
        </Form.Label>
        <Form.Control
          as="select"
          onChange={e => this.changeValue(e)}
          value={this.props.country.name}
        >
          {this.state.countryPhoneData.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    ) : (
      <div className="ddown-nationality">
        <Dropdown>
          <Dropdown.Toggle
            className="btn-room-people text-center"
            variant="none"
            drop="down"
            id="ddown-nat"
          >
            {this.props.country.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {this.state.countryPhoneData.map((item, index) => (
              <Dropdown.Item
                key={index}
                title={item.name}
                className="margin-b5"
                value={item.name}
              >
                <div
                  className="padding-tb5"
                  onClick={() => this.changeValueDrop(item.name)}
                >
                  {item.name}
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className="padding-lr20px txt-gray-blue-light font-light float-right">
          {this.props.t('NATIONALITY').toLowerCase()}
        </div>
      </div>
    );
  }
}

export default NationailtySelect;
