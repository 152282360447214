import * as allActions from './allActions';
import axios from 'axios';
import moment from 'moment';
import { scroller } from 'react-scroll';
import { animateScroll as scroll } from 'react-scroll';
import { HOTEL_API } from '../common/const';
import {
  totalAdultsChildren,
  // checkHomePageHotel,
  order,
  setFacilities
} from '../common/utils';
import { SEARCH_RADIUS } from '../common/const';

export function receiveData(data) {
  return { type: allActions.RECEIVE_DATA, hotels: data };
}
export function receiveTaData(data) {
  return { type: allActions.RECEIVE_TA_DATA, tripAdvisor: data };
}
export function setRedirectUrl(rUrl) {
  return { type: allActions.REDIRECT_URL, redirectUrl: rUrl };
}
export function changeLang(lang) {
  return { type: allActions.CHANGE_LANG, lang: lang };
}
export function changeCurrency(curr) {
  return { type: allActions.CHANGE_CURR, curr: curr };
}
export function changeCountry(country) {
  return { type: allActions.CHANGE_COUNTRY, country: country };
}
export function setShowLocal(showLocal) {
  return { type: allActions.SET_SHOW_LOCAL, showLocal: showLocal };
}
export function setLocVisited(locVisited) {
  return dispatch => {
    dispatch({ type: allActions.SET_LOC_VISITED, locVisited: locVisited });
  };
}

export function setGodMode(value) {
  return dispatch => {
    dispatch({
      type: allActions.SET_GOD_MODE,
      godMode: value
    });
  };
}

export function setExchangeRate(currVal) {
  return dispatch => {
    let currTemp = {};
    axios
      .get('/currencies/currency.json')
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          if (i === 0) currTemp['time'] = moment();
          currTemp[response.data[i].currency] = parseFloat(
            response.data[i][currVal]
          );
        }
      })
      .then(() => {
        dispatch({
          type: allActions.EXCHANGE_RATE,
          exchangeRate: currTemp
        });
      });
  };
}
export function receiveHotelInfo(data) {
  return { type: allActions.HOTEL_INFO, hotelInfo: data };
}
export function receivePriceValidation(data, roomNumber) {
  return {
    type: allActions.PRICE_VALIDATION,
    priceValidation: data,
    roomNumber: roomNumber
  };
}
export function clearFilter() {
  return dispatch => {
    dispatch({
      type: allActions.CLEAR_FILTER
    });
  };
}
export function cleanStore() {
  return dispatch => {
    dispatch({
      type: allActions.CLEAN
    });
  };
}
export function cleanStoreLocal() {
  return dispatch => {
    dispatch({
      type: allActions.CLEAN_LOCAL
    });
  };
}
export function setUserInfo(info) {
  return dispatch => {
    dispatch({
      type: allActions.SETUSERINFO,
      param: info
    });
  };
}
export function setSearchTime(searchTime) {
  return dispatch => {
    dispatch({
      type: allActions.SEARCH_TIME,
      searchTime: searchTime
    });
  };
}
export function setGMapLoaded(gMapLoaded) {
  return dispatch => {
    dispatch({
      type: allActions.G_MAP_LOADED,
      gMapLoaded: gMapLoaded
    });
  };
}
export function setSubStatus(subStatus) {
  return dispatch => {
    dispatch({
      type: allActions.SUBSCRIPTION_STATUS,
      subStatus: subStatus
    });
  };
}
export function showHotelsMap(show) {
  return dispatch => {
    dispatch({ type: allActions.SHOW_HOTELSMAP, hotelsMap: show });
  };
}
export function showMapInfo(mapInfo) {
  return dispatch => {
    dispatch({ type: allActions.SHOW_MAP_INFO, mapInfo: mapInfo });
  };
}
export function showMapInfoHover(mapInfoHover) {
  return dispatch => {
    dispatch({
      type: allActions.SHOW_MAP_INFO_HOVER,
      mapInfoHover: mapInfoHover
    });
  };
}
export function setRooms(rooms) {
  return dispatch => {
    dispatch({ type: allActions.ROOMS_INFO, rooms: rooms });
  };
}
export function setBookInfo(bookInfo) {
  return dispatch => {
    dispatch({ type: allActions.BOOK_INFO, bookInfo: bookInfo });
  };
}
export function setCitiesList(list) {
  return dispatch => {
    dispatch({ type: allActions.CITIES_LIST, citiesList: list });
  };
}
export function setHotelsHome(hotelsHome) {
  return dispatch => {
    dispatch({ type: allActions.HOTELS_HOME, hotelsHome: hotelsHome });
  };
}
export function setGuests(value, type) {
  return dispatch => {
    if (type === 'guests')
      dispatch({ type: allActions.GUESTS_INFO, guests: value });
    else dispatch({ type: allActions.GUESTS_CONTACT, guestsContact: value });
  };
}
export function setValidation(valid) {
  return dispatch => {
    dispatch({ type: allActions.VALIDATION, valid: valid });
  };
}
export function isComplete(complete) {
  return dispatch => {
    dispatch({
      type: allActions.IS_COMPLETE,
      complete: complete
    });
  };
}
export function setShowBar(bar, type) {
  return dispatch => {
    if (type === 'show') {
      dispatch({
        type: allActions.SHOW_BAR,
        showBar: bar
      });
    } else {
      dispatch({
        type: allActions.ACTIVE_BAR,
        activeBar: bar
      });
    }
  };
}
export function setCity(city) {
  return dispatch => {
    dispatch({ type: allActions.SET_CITY, city: city });
  };
}
export function setLatLng(lat, lng, city) {
  return dispatch => {
    dispatch({
      type: allActions.SET_COORDINATES,
      lat: lat,
      lng: lng,
      city: city
    });
  };
}
export function setCityEn(city) {
  return dispatch => {
    dispatch({ type: allActions.SET_CITY_EN, cityEn: city });
  };
}
export function setCityLocal(city) {
  return dispatch => {
    dispatch({ type: allActions.SET_CITY_LOCAL, cityLocal: city });
  };
}
export function setCityHome(city) {
  return dispatch => {
    dispatch({ type: allActions.SET_CITY_HOME, cityHome: city });
  };
}
export function showRooms(showRooms, activeRoom) {
  return dispatch => {
    dispatch({
      type: allActions.SHOW_ROOMS,
      showRooms: showRooms,
      activeRoom: activeRoom
    });
  };
}
export function modalCal(showModalCal, toggleCal) {
  return dispatch => {
    dispatch({
      type: allActions.MODAL_CALENDAR,
      showModalCal: showModalCal,
      toggleCal: toggleCal
    });
  };
}
export function setDate(date, which) {
  return dispatch => {
    if (which === 'start') {
      dispatch({ type: allActions.SET_START_DATE, date: date });
    } else {
      dispatch({ type: allActions.SET_END_DATE, date: date });
    }
  };
}
export function Filter(filtered) {
  let result = [];
  if (
    typeof filtered === 'undefined' ||
    filtered === null ||
    filtered.length <= 0
  ) {
    result = [];
  } else {
    result = filtered;
  }
  return dispatch => {
    dispatch({ type: allActions.FILTER, filtered: result });
  };
}
export function addVisited(visited) {
  return dispatch => {
    dispatch({ type: allActions.VISITED, visited: visited });
  };
}
export function addFavs(favs) {
  return dispatch => {
    dispatch({ type: allActions.FAVS, favs: favs });
  };
}
export function selectedHotel(hotel) {
  return dispatch => {
    dispatch({ type: allActions.SELECT_HOTEL, hotelSelected: hotel });
  };
}
export function getSearchInfo(searchInfo) {
  return dispatch => {
    dispatch({ type: allActions.SEARCH_INFO, searchInfo: searchInfo });
  };
}
export function setPack(pack, roomNumber) {
  return dispatch => {
    dispatch({
      type: allActions.PACK_SELECTED,
      packSelected: pack,
      roomNumber: roomNumber
    });
  };
}
export function setRouteInfo(route) {
  return dispatch => {
    dispatch({ type: allActions.ROUTE_INFO, routes: route });
  };
}
export function setModalInfo(modal) {
  return dispatch => {
    dispatch({ type: allActions.MODAL_INFO, modal: modal });
  };
}
export function setFilters(filters) {
  return dispatch => {
    dispatch({ type: allActions.FILTERS, filters: filters });
  };
}
export function setRadius(radius) {
  return dispatch => {
    dispatch({ type: allActions.SET_RADIUS, radius: radius });
  };
}
export function Order(orden) {
  return dispatch => {
    dispatch({ type: allActions.ORDER, order: orden });
  };
}
export function changePage(page) {
  return dispatch => {
    dispatch({ type: allActions.CHANGE_PAGE, page: page });
  };
}
export function setFilterModal(filterModal) {
  return dispatch => {
    dispatch({ type: allActions.FILTER_MODAL, filterModal: filterModal });
  };
}
export function setFilterMap(filterMap) {
  return dispatch => {
    dispatch({ type: allActions.FILTER_MAP, filterMap: filterMap });
  };
}
export function acceptCookies(param) {
  return { type: allActions.ACCEPTCOOKIES, param: param };
}
export function isError(error) {
  return { type: allActions.ERROR, msg: error };
}
export function loading(status) {
  return { type: allActions.LOADING, loading: status };
}
export function setComplete(val) {
  return { type: allActions.COMPLETED, completed: val };
}
// export function setAmenities(hotels) {
//   const amenities = [
//     'wifi',
//     'internet',
//     'restaurant',
//     'breakfast',
//     'accessib',
//     'pool',
//     'fitness',
//     'front desk',
//     'no smoking',
//     'parking',
//     'shuttle',
//     'heating',
//     'air condition',
//     'pets allowed'
//   ];
//   hoteles.map((hotel, i) => {

//   })
// }
// export function getTripAdvisor(hotels) {
//   let hoteles = hotels.slice();
//   let urlTA = 'https://hotelapi.sky-tours.com/hotels/ta.php?a=hotelshop';
//   // return function(dispatch) {
//   hoteles.map((hotel, i) => {
//     if (typeof hotel.location !== 'undefined' && hotel.location !== null) {
//       let nombre = hotel.name;
//       let lath = hotel.location.geo.lat;
//       let longh = hotel.location.geo.lon;
//       axios
//         .get(urlTA + '&name=' + nombre + '&lat=' + lath + '&lng=' + longh)
//         .then(response => {
//           let ta = response.data.response;
//           hoteles[i]['tripAdvisor'] = ta === 'Error' ? ta : ta.rating_img;
//         })
//         .catch(err => {});
//     }
//   });
//   return receiveData(hoteles);
// }

export function getHotelTripAdvisor(hotel) {
  // return function(dispatch) {
  let ta = {};
  if (typeof hotel.location !== 'undefined' && hotel.location !== null) {
    let name = hotel.name;
    let lath = hotel.location.geo.lat;
    let longh = hotel.location.geo.lon;
    let urlTA =
      HOTEL_API +
      'ta.php?a=hotelshop&name=' +
      name +
      '&lat=' +
      lath +
      '&lng=' +
      longh;
    axios
      .get(urlTA)
      .then(response => {
        ta = response.data.response;
        // console.log(urlTA, response.data.response);
      })
      .catch(err => {});
  }

  return receiveTaData(ta);
}

export function getHotelData(
  hid,
  currency,
  local,
  start,
  end,
  offsetStart,
  offsetEnd,
  city,
  lat,
  lng,
  rooms,
  roomsArray,
  guests
) {
  let urlHotelData =
    HOTEL_API +
    'hotels.php?a=hotelshop&method=search&_q=|' +
    moment(start)
      .add(offsetStart, 'days')
      .format('YYYYMMDD') +
    '|' +
    moment(end)
      .add(offsetEnd, 'days')
      .format('YYYYMMDD') +
    '|' +
    rooms +
    '|' +
    guests +
    '|5||' +
    hid +
    '&lang=' +
    local +
    '&' +
    'curr=' +
    currency +
    '&forceService=Priceline';
  return function(dispatch) {
    dispatch(loading('simple'));
    axios
      .get(urlHotelData)
      .catch(err => {
        dispatch(loading('none'));
        dispatch(isError(err.message));
      })
      .then(response => {
        // console.log('URL:', urlHotelData);
        // console.log('RESPONSE:', response);
        dispatch(loading('none'));
        if (
          typeof response !== 'undefined' &&
          response !== null &&
          response.status === 200
        ) {
          if (response.data.status !== 'ERR') {
            // dispatch(selectedHotel(response.data.response[0]));
            let searchUrl =
              encodeURIComponent(city) +
              // city.split(' ').join('_') +
              '&loc=' +
              lat +
              '&' +
              lng +
              '&from=' +
              moment(start).format('YYYYMMDD') +
              '&to=' +
              moment(end).format('YYYYMMDD') +
              '&rooms=' +
              totalAdultsChildren(roomsArray) +
              '&curr=' +
              currency +
              '&rad=5';

            dispatch(selectedHotel(response.data.response[[0]]));
            dispatch(
              getHotelInfo(
                null,
                searchUrl,
                response.data.response[0].hid,
                response.data.response[0].c_key,
                null,
                currency,
                local,
                false,
                true
              )
            );
          }
        } else {
        }
      });
  };
}

export function getHotelInfo(
  thisHotel,
  searchUrl,
  hid,
  akey,
  hotelsInfo,
  curr,
  lang,
  noRoute,
  scrollMe
) {
  if (!scrollMe) scroll.scrollToTop();
  // let sethotel = 0;
  // if (typeof setHotel == 'undefined') {
  //   sethotel = 0;
  // } else {
  //   sethotel = setHotel;
  // }
  let urlInfo =
    HOTEL_API +
    'hotels.php?method=info&a=hotelshop&_q=' +
    akey +
    '|' +
    hid +
    '&curr=' +
    curr +
    '&lang=' +
    lang;
  // console.log(urlInfo);
  return function(dispatch) {
    dispatch(setSearchTime(moment()));
    dispatch(receiveHotelInfo({}));
    // dispatch(loading(thisHotel !== null ? 'info' : 'simple'));
    dispatch(receivePriceValidation(null));
    if (thisHotel && thisHotel !== null) dispatch(selectedHotel(thisHotel));
    if (thisHotel != null && !noRoute)
      dispatch(
        setRouteInfo(
          '/' +
            lang +
            '/hotel/' +
            encodeURIComponent(thisHotel.name.split(' ').join('_')) +
            '/' +
            searchUrl +
            '&hid=' +
            hid +
            '&ckey=' +
            akey
        )
      );
    axios
      .get(urlInfo)
      .then(dispatch(loading('simple')))
      .then(response => {
        if (
          typeof response !== 'undefined' &&
          response !== null &&
          response.status === 200
        ) {
          let tempData = setFacilities([response.data.response], 'yes')[0];
          dispatch(selectedHotel(tempData));
          dispatch(receiveHotelInfo(tempData));
          dispatch(setPack({}));
          // console.log(response.data.response);
          getHotelTripAdvisor(response.data.response);
          dispatch(loading('none'));
          if (!response.data.response.hasOwnProperty('packs')) {
            setTimeout(
              scroller.scrollTo('NoAvailability', {
                duration: 1000,
                delay: 100,
                smooth: true
              }),
              500
            );
          }
          thisHotel === null &&
            response.data.status !== 'ERR' &&
            !noRoute &&
            dispatch(
              setRouteInfo(
                '/' +
                  lang +
                  '/hotel/' +
                  encodeURIComponent(
                    response.data.response.name.split(' ').join('_')
                  ) +
                  '/' +
                  searchUrl +
                  '&hid=' +
                  hid +
                  '&ckey=' +
                  akey
              )
            );
          // response.data.status === 'ERR' && dispatch(setRouteInfo('/page=404'));
        } else {
          dispatch(isError("ERROR: server couldn't be reached"));
          dispatch(loading('none'));
        }
      })
      .catch(err => {
        dispatch(loading('none'));
        dispatch(isError(err.message));
      });
  };
}

export function storeBookInfo(bookInfo) {
  debugger;
  let urlInfo =
    'https://email.hotelshop.com/writefile/book_info/' +
    bookInfo[2].order +
    '/json';
  return function(dispatch) {
    dispatch(setSearchTime(moment()));
    axios
      .post(urlInfo, { fileContent: bookInfo })
      .then(response => {
        if (response.data.msg === 'success') {
          dispatch(setBookInfo(bookInfo));
          dispatch(loading('none'));
        } else if (response.data.msg === 'fail') {
          dispatch(setBookInfo(bookInfo));
          dispatch(loading('none'));
        }
        window.location.hostname.includes('devel') &&
        !window.location.hostname.includes('devel5')
          ? window.location.assign(
              bookInfo[2].payment_url.replace(
                'https//',
                'https//' + window.location.hostname.split('.')[0]
              )
            )
          : window.location.assign(bookInfo[2].payment_url);
      })
      .catch(err => {
        dispatch(loading('none'));
        // window.location.hostname.includes('devel') &&
        // !window.location.hostname.includes('devel5')
        //   ? window.location.assign(
        //       bookInfo[2].payment_url.replace(
        //         'https//',
        //         'https//' + window.location.hostname.split('.')[0]
        //       )
        //     )
        //   : window.location.assign(bookInfo[2].payment_url);
        console.log('EMAIL BOOK_INFO ERROR', err);
      });
  };
}

export function storeReservation(
  product,
  contact,
  room,
  curr,
  lang,
  ip,
  bookInfo
) {
  let urlInfo =
    HOTEL_API +
    'hotels.php?method=store_reservation&a=hotelshop' +
    '&_q=' +
    product +
    '&curr=' +
    curr +
    room +
    'client_ip=' +
    ip +
    '&lang=' +
    lang +
    '&contact=' +
    contact;
  // console.log(urlInfo);
  return function(dispatch) {
    axios
      .get(urlInfo)
      .then(dispatch(loading('simple')))
      .then(response => {
        if (
          typeof response !== 'undefined' &&
          response !== null &&
          response.status === 200 &&
          response.data.status !== 'ERR'
        ) {
          dispatch(setSearchTime(moment()));
          let srInfo = response.data.response;
          let data = Object.assign({}, srInfo);
          dispatch(setStoreReservation(data));
          bookInfo[2] = data;
          console.log(bookInfo);
          //remove storeBookInfo as it was created to log booking details
          //dispatch(storeBookInfo(bookInfo));
          if (data.payment_url) {
            window.location.assign(data.payment_url);
          } else {
            //TODO: Add a different message or a page to show error
            dispatch(isError("Sorry the payment page couldn't be open"));
          }
        } else {
          dispatch(isError("ERROR: server couldn't be reached"));
        }
      })
      .catch(err => {
        dispatch(loading('none'));
        dispatch(isError(err.message));
      });
  };
}
export function getPriceValidation(
  pack,
  hid,
  akey,
  roomId,
  curr,
  url,
  lang,
  isLBP,
  nonRefLBPFunction,
  isMultiRoom,
  roomNumber
) {
  // let packnumber = pack;
  // console.log(pack, hid, akey, roomId, curr, url, lang)
  let urlInfo =
    HOTEL_API +
    'hotels.php?method=pricevalidate&a=hotelshop' +
    '&curr=' +
    curr +
    '&lang=' +
    lang +
    '&_q=' +
    akey +
    '|' +
    hid +
    '|' +
    roomId;
  return function(dispatch) {
    axios
      .get(urlInfo)
      .then(url !== 'none' && dispatch(loading('simple')))
      .then(response => {
        if (
          typeof response !== 'undefined' &&
          response !== null &&
          response.status === 200
        ) {
          // console.log('response.data', urlInfo, response.data);
          if (response.data.status === 'ERR') {
            dispatch(loading('none'));
            // dispatch(isError('Sold Out, try other kind of room or Hotel'));
            dispatch(setModalInfo('message3'));
            // dispatch(setPack(null));
          } else {
            // if (
            //   isLBP &&
            //   response.data.response.cnx_fee &&
            //   response.data.response.cnx_fee[0] &&
            //   response.data.response.cnx_fee[0].fee
            // ) {
            //   dispatch(loading('none'));
            //   nonRefLBPFunction();
            // } else {
            dispatch(setSearchTime(moment()));
            let hInfo = response.data.response;
            hInfo['roomId'] = roomId;
            let data = Object.assign({}, hInfo);
            dispatch(receivePriceValidation(data, roomNumber));
            if (url !== 'none') {
              pack.hid = hid;
              pack.packNumber = roomId;
              pack.ckey = akey;
              if (isMultiRoom) {
                dispatch(setPack(pack, roomNumber));
                dispatch(setBookInfo([pack]));
                dispatch(loading('none'));
                scroller.scrollTo(roomId, {
                  duration: 1000,
                  delay: 300,
                  smooth: true
                });
              } else {
                dispatch(setPack(pack));
                dispatch(setBookInfo([pack]));
                dispatch(loading('none'));
                scroller.scrollTo(roomId, {
                  duration: 1000,
                  delay: 300,
                  smooth: true
                });
              }
              // }
            }
            // dispatch(setRouteInfo(url));
          }
        } else {
          dispatch(isError("ERROR: server couldn't be reached"));
        }
      })
      .catch(err => {
        dispatch(loading(false));
        dispatch(isError(err.message));
      });
  };
}
// export function checkStatus(order, hid, ckey, roomId, curr) {
//   return function(dispatch) {
//     axios
//       .get(
//         'https://devel5.order.hotelshop.com/index.php?_r=' +
//           order +
//           '&_hid=' +
//           hid +
//           '&_ckey=' +
//           ckey +
//           '&_pkey=' +
//           roomId +
//           '&_curr=' +
//           curr
//       )
//       .then(function(response) {
//         let status = response.data.status;
//         if (status === 'OK') {
//           dispatch(loading(false));
//           dispatch(setComplete(true));
//         } else {
//           dispatch(setComplete(false));
//           dispatch(loading(false));
//         }
//       })
//       .catch(function(error) {
//         debugger;

//         dispatch(loading(false));
//         dispatch(setComplete(false));
//         dispatch(isError(error.message));
//       });
//   };
// }
export function fetchData(
  where,
  start,
  end,
  curr,
  lat,
  lon,
  locale,
  rooms,
  roomsArray,
  guests,
  radius,
  replace,
  selectedHid,
  cities,
  addNew,
  ifCityName,
  forceService,
  country
) {
  // console.log('AGAIN HERE');
  let param = undefined;
  let city = param || where;
  let dstart = param || moment(start).format('YYYYMMDD');
  let dend = param || moment(end).format('YYYYMMDD');
  let currency = param || curr;
  let lati = param || lat;
  let long = param || lon;
  let local = param || locale;
  let room = param || rooms;
  let guest = param || guests;
  let rad = param || radius;
  if (
    dstart !== param &&
    dend !== param &&
    lati !== param &&
    long !== param &&
    room !== param &&
    moment(dstart).format('YYYYMMDD') >= moment().format('YYYYMMDD')
  ) {
    let forceServiceTemp =
      forceService && forceService !== '--'
        ? `&forceService=${forceService}`
        : '';
    let tempInfo = {
      city: city,
      lat: lati,
      lng: long,
      start: dstart,
      end: dend,
      rooms: roomsArray,
      rad: radius,
      searchLang: local,
      searchUrl:
        '/' +
        local +
        '/search/' +
        encodeURIComponent(city) +
        // city.split(' ').join('_') +
        '&loc=' +
        lati +
        '&' +
        long +
        '&from=' +
        moment(dstart).format('YYYYMMDD') +
        '&to=' +
        moment(dend).format('YYYYMMDD') +
        '&rooms=' +
        totalAdultsChildren(roomsArray) +
        '&curr=' +
        currency +
        '&rad=' +
        rad +
        '&country=' +
        country
    };
    let emptyfilters = [
      { msg: '' },
      { name: '' },
      { stars: [] },
      { rating: -1 },
      { facilities: [] },
      { price: [0, 0], priceSlider: [0, 0] },
      { type: [] },
      { offer: false, hasOffers: 0 }
    ];
    let url1 =
      HOTEL_API +
      'hotels.php?method=search&a=hotelshop&_q=' +
      lati +
      ';' +
      long +
      '|' +
      dstart +
      '|' +
      dend +
      '|' +
      room +
      '|' +
      guest +
      '|' +
      rad +
      '|||' +
      country +
      '|&city_name=' +
      encodeURIComponent(city.split(', ').join(',')) +
      '&curr=' +
      currency +
      '&lang=' +
      local +
      forceServiceTemp;
    if (selectedHid) {
      url1 =
        HOTEL_API +
        'hotels.php?method=search&a=hotelshop&_q=' +
        selectedHid.location.geo.lat +
        ';' +
        selectedHid.location.geo.lon +
        '|' +
        dstart +
        '|' +
        dend +
        '|' +
        room +
        '|' +
        guest +
        '|' +
        '1' +
        '|||' +
        country +
        '&city_name=' +
        encodeURIComponent(city.split(', ').join(',')) +
        '&curr=' +
        currency +
        '&lang=' +
        local +
        forceServiceTemp;
    }
    // console.log(url1);
    let selected = null;
    return function(dispatch) {
      axios
        .get(url1)
        .then(dispatch(loading('simple')))
        .catch(err => {
          dispatch(loading('none'));
          dispatch(isError(err.message));
        })
        .then(response => {
          if (
            typeof response !== 'undefined' &&
            response !== null &&
            response.status === 200
          ) {
            // console.log('RESPONSE', response.data.response);
            if (response.data.status === 'ERR') {
              dispatch(setModalInfo('message5'));
              dispatch(loading('none'));
            } else {
              if (rad) {
                // console.log(response.data.response);
                dispatch(setCityLocal(' '));
                dispatch(getSearchInfo(tempInfo));
                dispatch(loading('none'));
                dispatch(setSearchTime(moment()));
                dispatch(setFilters(emptyfilters));
                dispatch(Order(['total_price', true]));
                dispatch(changePage(1));
                dispatch(showMapInfo('none'));
                let tempData = setFacilities(response.data.response);
                dispatch(receiveData(tempData));
                if (ifCityName === 'no_name') {
                  dispatch(setCityLocal(city));
                  dispatch(setLatLng(lati, long, city));
                } else {
                  axios
                    .post('https://email.hotelshop.com/googleplacestextquery', {
                      inputQuery: city,
                      language: local
                    })
                    .catch(err => {
                      dispatch(setCityLocal(city));
                      dispatch(setLatLng(lati, long, city));
                    })
                    .then(response => {
                      if (response && response.status === 200) {
                        let res1 = JSON.parse(response.data);
                        if (res1.status === 'OK') {
                          dispatch(
                            setCityLocal(res1.candidates[0].formatted_address)
                          );
                          dispatch(
                            setLatLng(
                              lati,
                              long,
                              res1.candidates[0].formatted_address
                            )
                          );
                        } else {
                          dispatch(setCityLocal(city));
                          dispatch(setLatLng(lati, long, city));
                        }
                      }
                    });
                }
              }
              dispatch(setPack(null));
              if (selectedHid && response.data.response.length > 0) {
                response.data.response.filter(function(obj) {
                  if (obj.hid === selectedHid.hid) selected = obj;
                });
                if (selected) {
                  dispatch(
                    getHotelInfo(
                      selected,
                      tempInfo.searchUrl.replace('/' + local + '/search/', ''),
                      selected.hid,
                      selected.c_key,
                      null,
                      currency,
                      local,
                      false,
                      true
                    )
                  );
                } else {
                  delete selectedHid.packs;
                  dispatch(receiveHotelInfo(selectedHid));
                  dispatch(loading('none'));
                }
              }
              if (!selectedHid) {
                !addNew && scroll.scrollToTop();
                dispatch(receiveHotelInfo({}));
                dispatch(setRouteInfo(replace ? 'searchReplace' : 'search'));
                // if (
                //   (rad === 5 || rad === '5') &&
                //   response.data.response.length > 100
                // ) {
                //   let result = ['active']; //active data
                //   let data = response.data.response.slice();
                //   result = result.concat(
                //     [data.sort(order('exclusive', false)).slice(0, 12)], //exclusive
                //     [data.sort(order('review', false)).slice(0, 12)], //best rated
                //     [data.sort(order('recomended', false)).slice(0, 12)], //recomended
                //     [data.sort(order('total_price', true)).slice(0, 12)]
                //   ); //cheapest
                //   dispatch(setHotelsHome(result));
                //   cities[0] = 0;
                //   dispatch(setCitiesList(cities));
                // }
              }
              // dispatch(getTripAdvisor(response.data.response));
              // dispatch(addVisited([]));
            }
          } else {
            dispatch(isError("ERROR: server couldn't be reached"));
          }
        });
    };
  } else
    return function(dispatch) {
      // axios
      //   .get('/test')
      //   .then(dispatch(loading('main')))
      //   .catch(err => {
      //     dispatch(loading('none'));
      //     dispatch(isError(err.message));
      //   })
      //   .then(response => {
      //     if (
      //       typeof response !== 'undefined' &&
      //       response !== null &&
      //       response.status === 200
      //     ) {
      //       dispatch(receiveData(response.data.response));
      //       dispatch(loading('none'));
      //     } else {
      //       dispatch(isError("ERROR: server couldn't be reached"));
      //     }
      //   });
      dispatch(setModalInfo('message4'));
    };
}

export function setStoreReservation(data) {
  return { type: allActions.STORE_RESERVATION, storeReservation: data };
}

export function setConfirmationInfo(storeReservation) {
  return dispatch => {
    dispatch(loading('main'));
    let reqUrl =
      'https://affiliateapi.militaryfares.com/front/order-data.php?tname=' +
      storeReservation.lastName +
      '&orderid=' +
      storeReservation.orderId +
      '&lang=' +
      storeReservation.lang;
    axios
      .get(reqUrl)
      .catch(err => {
        dispatch(loading('none'));
        console.log(err);
      })
      .then(response => {
        if (response && response.status === 200) {
          dispatch(loading('none'));
          if (
            response.data.status &&
            (response.data.status === '1' || response.data.status === '2')
          )
            dispatch({
              type: allActions.CONFIRMATION_INFO,
              confirmationInfo: response.data
            });
          else {
            dispatch(loading('none'));
            // for testing set status to 1 so confirmation page is being displayed always
            // response.data.status="1"
            // end testing
            dispatch({
              type: allActions.CONFIRMATION_INFO,
              confirmationInfo: { status: '-10' }
            });
          }
        } else {
          dispatch(loading('none'));
          dispatch({
            type: allActions.CONFIRMATION_INFO,
            confirmationInfo: { status: '-10' }
          });
        }
      });
  };
}
