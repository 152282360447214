let currency = [
  // CURRENCIES WITH SYMBOLS
  { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { cc: 'USD', symbol: 'US$', name: 'United States dollar' },
  { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { cc: '- - -', symbol: '- - -', name: '- - -' },
  //
  { cc: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
  { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
  { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
  { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
  { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
  { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
  { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
  { cc: 'AUD', symbol: '$', name: 'Australian dollar' },
  { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
  { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
  {
    cc: 'BAM',
    symbol: 'KM',
    name: 'Bosnia and Herzegovina konvertibilna marka'
  },
  { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
  { cc: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
  { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
  { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
  { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
  { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
  { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
  { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
  { cc: 'BRL', symbol: 'R$', name: 'Brazilian real' },
  { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
  { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
  { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
  { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
  { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
  { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
  { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
  { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
  { cc: 'CLP', symbol: '$', name: 'Chilean peso' },
  { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
  { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
  { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
  { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
  { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
  { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
  { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
  { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
  { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
  { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
  { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
  { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
  { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
  { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
  { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
  { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
  { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
  { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
  { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
  { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
  { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
  { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
  { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
  { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
  { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
  { cc: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
  { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
  { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
  { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
  { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
  { cc: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
  { cc: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
  { cc: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
  { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
  { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
  { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
  { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
  { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
  { cc: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
  { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
  { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
  { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
  { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
  { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
  { cc: 'KRW', symbol: 'W', name: 'South Korean won' },
  { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
  { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
  { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
  { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
  { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
  { cc: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
  { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
  { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
  { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
  { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
  { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
  { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
  { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
  { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
  { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
  { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
  { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
  { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
  { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
  { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
  { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
  { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
  { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
  { cc: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
  { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
  { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
  { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
  { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
  { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
  { cc: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
  { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
  { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
  { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
  { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
  { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
  { cc: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
  { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
  { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
  { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
  { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
  { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
  { cc: 'RUB', symbol: 'R', name: 'Russian ruble' },
  { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
  { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
  { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
  { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
  { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  { cc: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
  { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
  { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
  { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
  { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
  { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
  { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
  { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
  { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
  { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
  { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
  { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
  { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
  { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
  { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
  { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
  { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
  { cc: 'USD', symbol: 'US$', name: 'United States dollar' },
  { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
  { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
  { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
  { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
  { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
  { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
  { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
  { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
  { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
  { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
  { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
  { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
  { cc: 'ZAR', symbol: 'R', name: 'South African rand' },
  { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
  { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' }

  // ALL THE CURRENCIES PER COUNTRY
  // {code:"NC",name:"New Caledonia",currency:"XPF"},
  // {code:"MT",name:"Malta",currency:"EUR"},
  // {code:"BN",name:"Brunei",currency:"BND"},
  // {code:"BT",name:"Bhutan",currency:"BTN"},
  // {code:"BZ",name:"Belize",currency:"BZD"},
  // {code:"CA",name:"Canada",currency:"CAD"},
  // {code:"AD",name:"Andorra",currency:"EUR"},
  // {code:"AU",name:"Australia",currency:"AUD"},
  // {code:"BS",name:"Bahamas",currency:"BSD"},
  // {code:"CY",name:"Cyprus",currency:"EUR"},
  // {code:"AW",name:"Aruba",currency:"AWG"},
  // {code:"BH",name:"Bahrain",currency:"BHD"},
  // {code:"BB",name:"Barbados",currency:"BBD"},
  // {code:"EE",name:"Estonia",currency:"EUR"},
  // {code:"CR",name:"Costa Rica",currency:"CRC"},
  // {code:"EG",name:"Egypt",currency:"EGP"},
  // {code:"GB",name:"United Kingdom",currency:"GBP"},
  // {code:"KR",name:"South Korea",currency:"KRW"},
  // {code:"CU",name:"Cuba",currency:"CUP"},
  // {code:"JM",name:"Jamaica",currency:"JMD"},
  // {code:"CL",name:"Chile",currency:"CLP"},
  // {code:"KW",name:"Kuwait",currency:"KWD"},
  // {code:"ES",name:"Spain",currency:"EUR"},
  // {code:"HK",name:"Hong Kong",currency:"HKD"},
  // {code:"KI",name:"Kiribati",currency:"AUD"},
  // {code:"KM",name:"Comoros",currency:"KMF"},
  // {code:"ET",name:"Ethiopia",currency:"ETB"},
  // {code:"KH",name:"Cambodia",currency:"KHR"},
  // {code:"IE",name:"Ireland",currency:"EUR"},
  // {code:"AI",name:"Anguilla",currency:"XCD"},
  // {code:"BM",name:"Bermuda",currency:"BMD"},
  // {code:"JE",name:"Jersey",currency:"GBP"},
  // {code:"IS",name:"Iceland",currency:"ISK"},
  // {code:"LA",name:"Laos",currency:"LAK"},
  // {code:"HT",name:"Haiti",currency:"HTG"},
  // {code:"LT",name:"Lithuania",currency:"LTL"},
  // {code:"MC",name:"Monaco",currency:"EUR"},
  // {code:"MQ",name:"Martinique",currency:"EUR"},
  // {code:"BQ",name:"Bonaire, Saint Eustatius and Saba ",currency:"USD"},
  // {code:"EH",name:"Western Sahara",currency:"MAD"},
  // {code:"NR",name:"Nauru",currency:"AUD"},
  // {code:"PW",name:"Palau",currency:"USD"},
  // {code:"TL",name:"East Timor",currency:"USD"},
  // {code:"AQ",name:"Antarctica",currency:""},
  // {code:"CZ",name:"Czech Republic",currency:"CZK"},
  // {code:"LY",name:"Libya",currency:"LYD"},
  // {code:"LB",name:"Lebanon",currency:"LBP"},
  // {code:"LI",name:"Liechtenstein",currency:"CHF"},
  // {code:"LK",name:"Sri Lanka",currency:"LKR"},
  // {code:"LU",name:"Luxembourg",currency:"EUR"},
  // {code:"LV",name:"Latvia",currency:"LVL"},
  // {code:"MA",name:"Morocco",currency:"MAD"},
  // {code:"ME",name:"Montenegro",currency:"EUR"},
  // {code:"OM",name:"Oman",currency:"OMR"},
  // {code:"TN",name:"Tunisia",currency:"TND"},
  // {code:"MV",name:"Maldives",currency:"MVR"},
  // {code:"NP",name:"Nepal",currency:"NPR"},
  // {code:"PH",name:"Philippines",currency:"PHP"},
  // {code:"PG",name:"Papua New Guinea",currency:"PGK"},
  // {code:"PY",name:"Paraguay",currency:"PYG"},
  // {code:"PA",name:"Panama",currency:"PAB"},
  // {code:"QA",name:"Qatar",currency:"QAR"},
  // {code:"RE",name:"Reunion",currency:"EUR"},
  // {code:"RW",name:"Rwanda",currency:"RWF"},
  // {code:"SC",name:"Seychelles",currency:"SCR"},
  // {code:"SI",name:"Slovenia",currency:"EUR"},
  // {code:"TW",name:"Taiwan",currency:"TWD"},
  // {code:"NE",name:"Niger",currency:"XOF"},
  // {code:"NG",name:"Nigeria",currency:"NGN"},
  // {code:"CN",name:"China",currency:"CNY"},
  // {code:"GN",name:"Guinea",currency:"GNF"},
  // {code:"GU",name:"Guam",currency:"USD"},
  // {code:"BG",name:"Bulgaria",currency:"BGN"},
  // {code:"AT",name:"Austria",currency:"EUR"},
  // {code:"BF",name:"Burkina Faso",currency:"XOF"},
  // {code:"HU",name:"Hungary",currency:"HUF"},
  // {code:"AZ",name:"Azerbaijan",currency:"AZN"},
  // {code:"GA",name:"Gabon",currency:"XAF"},
  // {code:"DO",name:"Dominican Republic", },
  // {code:"AL",name:"Albania",currency:"ALL"},
  // {code:"BR",name:"Brazil",currency:"BRL"},
  // {code:"FJ",name:"Fiji",currency:"FJD"},
  // {code:"ZW",name:"Zimbabwe",currency:"ZWD"},
  // {code:"ZA",name:"South Africa",currency:"ZAR"},
  // {code:"YE",name:"Yemen",currency:"YER"},
  // {code:"XK",name:"Kosovo",currency:"EUR"},
  // {code:"VE",name:"Venezuela",currency:"VEF"},
  // {code:"TM",name:"Turkmenistan",currency:"TMT"},
  // {code:"TH",name:"Thailand",currency:"THB"},
  // {code:"TD",name:"Chad",currency:"XAF"},
  // {code:"TG",name:"Togo",currency:"XOF"},
  // {code:"US",name:"United States",currency:"USD"},
  // {code:"SD",name:"Sudan",currency:"SDG"},
  // {code:"SA",name:"Saudi Arabia",currency:"SAR"},
  // {code:"RO",name:"Romania",currency:"RON"},
  // {code:"PK",name:"Pakistan",currency:"PKR"},
  // {code:"PE",name:"Peru",currency:"PEN"},
  // {code:"NO",name:"Norway",currency:"NOK"},
  // {code:"NL",name:"Netherlands",currency:"EUR"},
  // {code:"NI",name:"Nicaragua",currency:"NIO"},
  // {code:"SV",name:"El Salvador",currency:"USD"},
  // {code:"MW",name:"Malawi",currency:"MWK"},
  // {code:"IR",name:"Iran",currency:"IRR"},
  // {code:"MN",name:"Mongolia",currency:"MNT"},
  // {code:"PL",name:"Poland",currency:"PLN"},
  // {code:"KZ",name:"Kazakhstan",currency:"KZT"},
  // {code:"HR",name:"Croatia",currency:"HRK"},
  // {code:"MY",name:"Malaysia",currency:"MYR"},
  // {code:"IT",name:"Italy",currency:"EUR"},
  // {code:"SR",name:"Suriname",currency:"SRD"},
  // {code:"JO",name:"Jordan",currency:"JOD"},
  // {code:"MX",name:"Mexico",currency:"MXN"},
  // {code:"KG",name:"Kyrgyzstan",currency:"KGS"},
  // {code:"ID",name:"Indonesia",currency:"IDR"},
  // {code:"GY",name:"Guyana",currency:"GYD"},
  // {code:"HN",name:"Honduras",currency:"HNL"},
  // {code:"GP",name:"Guadeloupe",currency:"EUR"},
  // {code:"GH",name:"Ghana",currency:"GHS"},
  // {code:"GE",name:"Georgia",currency:"GEL"},
  // {code:"FR",name:"France",currency:"EUR"},
  // {code:"CO",name:"Colombia",currency:"COP"},
  // {code:"CI",name:"Ivory Coast",currency:"XOF"},
  // {code:"CM",name:"Cameroon",currency:"XAF"},
  // {code:"IN",name:"India",currency:"INR"},
  // {code:"AM",name:"Armenia",currency:"AMD"},
  // {code:"DE",name:"Germany",currency:"EUR"},
  // {code:"BO",name:"Bolivia",currency:"BOB"},
  // {code:"BJ",name:"Benin",currency:"XOF"},
  // {code:"DZ",name:"Algeria",currency:"DZD"},
  // {code:"BD",name:"Bangladesh",currency:"BDT"},
  // {code:"BE",name:"Belgium",currency:"EUR"},
  // {code:"CH",name:"Switzerland",currency:"CHF"},
  // {code:"SK",name:"Slovakia",currency:"EUR"},
  // {code:"SN",name:"Senegal",currency:"XOF"},
  // {code:"TR",name:"Turkey",currency:"TRY"},
  // {code:"SE",name:"Sweden",currency:"SEK"},
  // {code:"SG",name:"Singapore",currency:"SGD"},
  // {code:"TV",name:"Tuvalu",currency:"AUD"},
  // {code:"ML",name:"Mali",currency:"XOF"},
  // {code:"GD",name:"Grenada",currency:"XCD"},
  // {code:"YT",name:"Mayotte",currency:"EUR"},
  // {code:"VU",name:"Vanuatu",currency:"VUV"},
  // {code:"WS",name:"Samoa",currency:"WST"},
  // {code:"TT",name:"Trinidad and Tobago",currency:"TTD"},
  // {code:"KE",name:"Kenya",currency:"KES"},
  // {code:"GM",name:"Gambia",currency:"GMD"},
  // {code:"NU",name:"Niue",currency:"NZD"},
  // {code:"TO",name:"Tonga",currency:"TOP"},
  // {code:"SX",name:"Sint Maarten",currency:"ANG"},
  // {code:"CV",name:"Cape Verde",currency:"CVE"},
  // {code:"FM",name:"Micronesia",currency:"USD"},
  // {code:"PS",name:"Palestinian Territory",currency:"ILS"},
  // {code:"GW",name:"Guinea-Bissau",currency:"XOF"},
  // {code:"BI",name:"Burundi",currency:"BIF"},
  // {code:"LC",name:"Saint Lucia",currency:"XCD"},
  // {code:"SZ",name:"Swaziland",currency:"SZL"},
  // {code:"UA",name:"Ukraine",currency:"UAH"},
  // {code:"SO",name:"Somalia",currency:"SOS"},
  // {code:"IL",name:"Israel",currency:"ILS"},
  // {code:"BY",name:"Belarus",currency:"BYR"},
  // {code:"IM",name:"Isle of Man",currency:"GBP"},
  // {code:"LS",name:"Lesotho",currency:"LSL"},
  // {code:"CK",name:"Cook Islands",currency:"NZD"},
  // {code:"VI",name:"U.S. Virgin Islands",currency:"USD"},
  // {code:"DM",name:"Dominica",currency:"XCD"},
  // {code:"AG",name:"Antigua and Barbuda",currency:"XCD"},
  // {code:"PF",name:"French Polynesia",currency:"XPF"},
  // {code:"CD",name:"Democratic Republic of the Congo",currency:"CDF"},
  // {code:"NA",name:"Namibia",currency:"NAD"},
  // {code:"MO",name:"Macao",currency:"MOP"},
  // {code:"SH",name:"Saint Helena",currency:"SHP"},
  // {code:"ZM",name:"Zambia",currency:"ZMK"},
  // {code:"MG",name:"Madagascar",currency:"MGA"},
  // {code:"GF",name:"French Guiana",currency:"EUR"},
  // {code:"KN",name:"Saint Kitts and Nevis",currency:"XCD"},
  // {code:"ST",name:"Sao Tome and Principe",currency:"STD"},
  // {code:"AF",name:"Afghanistan",currency:"AFN"},
  // {code:"KP",name:"North Korea",currency:"KPW"},
  // {code:"GL",name:"Greenland",currency:"DKK"},
  // {code:"GG",name:"Guernsey",currency:"GBP"},
  // {code:"PM",name:"Saint Pierre and Miquelon",currency:"EUR"},
  // {code:"CC",name:"Cocos Islands",currency:"AUD"},
  // {code:"FO",name:"Faroe Islands",currency:"DKK"},
  // {code:"IO",name:"British Indian Ocean Territory",currency:"USD"},
  // {code:"GI",name:"Gibraltar",currency:"GIP"},
  // {code:"MH",name:"Marshall Islands",currency:"USD"},
  // {code:"NF",name:"Norfolk Island",currency:"AUD"},
  // {code:"SJ",name:"Svalbard and Jan Mayen",currency:"NOK"},
  // {code:"PN",name:"Pitcairn",currency:"NZD"},
  // {code:"PR",name:"Puerto Rico",currency:"USD"},
  // {code:"SB",name:"Solomon Islands",currency:"SBD"},
  // {code:"TF",name:"French Southern Territories",currency:"EUR"},
  // {code:"VG",name:"British Virgin Islands",currency:"USD"},
  // {code:"MM",name:"Myanmar",currency:"MMK"},
  // {code:"MF",name:"Saint Martin",currency:"EUR"},
  // {code:"GS",name:"South Georgia and the South Sandwich Islands",currency:"GBP"},
  // {code:"SS",name:"South Sudan",currency:""},
  // {code:"BW",name:"Botswana",currency:"BWP"},
  // {code:"AX",name:"Aland Islands",currency:"EUR"},
  // {code:"BL",name:"Saint Barthélemy",currency:"EUR"},
  // {code:"VA",name:"Vatican",currency:"EUR"},
  // {code:"SM",name:"San Marino",currency:"EUR"},
  // {code:"TC",name:"Turks and Caicos Islands",currency:"USD"},
  // {code:"MZ",name:"Mozambique",currency:"MZN"},
  // {code:"HM",name:"Heard Island and McDonald Islands",currency:"AUD"},
  // {code:"MP",name:"Northern Mariana Islands",currency:"USD"},
  // {code:"KY",name:"Cayman Islands",currency:"KYD"},
  // {code:"AS",name:"American Samoa",currency:"USD"},
  // {code:"EC",name:"Ecuador",currency:"USD"},
  // {code:"DJ",name:"Djibouti",currency:"DJF"},
  // {code:"DK",name:"Denmark",currency:"DKK"},
  // {code:"FI",name:"Finland",currency:"EUR"},
  // {code:"JP",name:"Japan",currency:"JPY"},
  // {code:"MD",name:"Moldova",currency:"MDL"},
  // {code:"MU",name:"Mauritius",currency:"MUR"},
  // {code:"NZ",name:"New Zealand",currency:"NZD"},
  // {code:"PT",name:"Portugal",currency:"EUR"},
  // {code:"BA",name:"Bosnia and Herzegovina",currency:"BAM"},
  // {code:"AR",name:"Argentina",currency:"ARS"},
  // {code:"VC",name:"Saint Vincent and the Grenadines",currency:"XCD"},
  // {code:"UY",name:"Uruguay",currency:"UYU"},
  // {code:"UZ",name:"Uzbekistan",currency:"UZS"},
  // {code:"UG",name:"Uganda",currency:"UGX"},
  // {code:"TJ",name:"Tajikistan",currency:"TJS"},
  // {code:"SY",name:"Syria",currency:"SYP"},
  // {code:"MK",name:"Macedonia",currency:"MKD"},
  // {code:"SL",name:"Sierra Leone",currency:"SLL"},
  // {code:"RS",name:"Serbia",currency:"RSD"},
  // {code:"MR",name:"Mauritania",currency:"MRO"},
  // {code:"GQ",name:"Equatorial Guinea",currency:"XAF"},
  // {code:"GT",name:"Guatemala",currency:"GTQ"},
  // {code:"IQ",name:"Iraq",currency:"IQD"},
  // {code:"CG",name:"Republic of the Congo",currency:"XAF"},
  // {code:"UM",name:"United States Minor Outlying Islands",currency:"USD"},
  // {code:"AO",name:"Angola",currency:"AOA"},
  // {code:"TZ",name:"Tanzania",currency:"TZS"},
  // {code:"CW",name:"Curaçao",currency:"ANG"},
  // {code:"TK",name:"Tokelau",currency:"NZD"},
  // {code:"ER",name:"Eritrea",currency:"ERN"},
  // {code:"LR",name:"Liberia",currency:"LRD"},
  // {code:"MS",name:"Montserrat",currency:"XCD"},
  // {code:"VN",name:"Vietnam",currency:"VND"},
  // {code:"BV",name:"Bouvet Island",currency:"NOK"},
  // {code:"AE",name:"United Arab Emirates",currency:"AED"},
  // {code:"CX",name:"Christmas Island",currency:"AUD"},
  // {code:"FK",name:"Falkland Islands",currency:"FKP"},
  // {code:"WF",name:"Wallis and Futuna",currency:"XPF"},
  // {code:"CF",name:"Central African Republic",currency:"XAF"},
  // {code:"GR",name:"Greece",currency:"EUR"},
  // {code:"RU",name:"Russia",currency:"RUB"}
];

export default currency;
