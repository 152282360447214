import moment from 'moment';
import axios from 'axios';

export function shorten(str, len, ellipsis = '…') {
  if (typeof str !== 'undefined' && str !== null) {
    if (str.length <= len) return str;

    let result = str.substr(0, len - 1);
    result = result.substr(0, Math.min(result.length, result.lastIndexOf(' ')));

    return result + ellipsis;
  } else {
    return ellipsis;
  }
}

export function paginate(array, page_size, page_number) {
  --page_number; // because pages logically start with 1, but technically with 0
  return array.slice(page_number * page_size, (page_number + 1) * page_size);
}

export function shuffleArray(array) {
  let ctr = array.length,
    temp,
    index;
  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr--;
    temp = array[ctr];
    array[ctr] = array[index];
    array[index] = temp;
  }
  return array;
}

export function checkScreenSize() {
  let w = window.outerWidth;
  let h = window.outerHeight;
  return [w, h];
}

export function ordinalIndexOf(str, substr, n) {
  let pos = str.indexOf(substr);
  while (--n > 0 && pos !== -1) pos = str.indexOf(substr, pos + 1);
  return pos;
}

export function listHelper(n) {
  let temp = [];
  for (let i = 0; i < n; i++) temp[i] = i;
  return temp;
}

export function arrayHelper(a1, a2) {
  let temp = [a1, a2];
  return temp;
}

export function imgOrientation(image) {
  let img = new Image(image);
  let orientation = '';
  img.onload = function() {
    if (img.naturalWidth > img.naturalHeight) {
      orientation = 'landscape';
    } else if (img.naturalWidth < img.naturalHeight) {
      orientation = 'portrait';
    } else {
      orientation = 'even';
    }
    return orientation;
  };
}

export function isNumber(str) {
  let pattern = /^[0-9]+$/;
  return pattern.test(str);
}

export function checkObjectValues(obj, arg1, arg2, arg3) {
  let temp = true;
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && key !== 'finished') {
      if (obj[key] === arg1 || obj[key] === arg2 || obj[key] === arg3)
        return (temp = false);
    }
  }
  return temp;
}

export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function totalAdultsChildren(rooms) {
  let temp = '';
  for (let i = 0; i < rooms.length; i++) {
    temp =
      temp +
      rooms[i][0] +
      ':' +
      rooms[i][1] +
      (rooms[i][1] > 0 ? ':' + JSON.stringify(rooms[i][2]) : '');
    if (i !== rooms.length - 1) temp = temp + ';';
  }
  return temp;
}

export function totalAdultsChildrenNumber(rooms) {
  let temp = 0;
  for (let i = 0; i < rooms.length; i++)
    temp = temp + rooms[i][0] + rooms[i][1];
  return temp;
}

export function totalAdults(rooms) {
  let temp = 0;
  for (let i = 0; i < rooms.length; i++) temp = temp + rooms[i][0];
  return temp;
}

export function totalChildren(rooms) {
  let temp = 0;
  for (let i = 0; i < rooms.length; i++) temp = temp + rooms[i][1];
  return temp;
}

export function roomToArray(roomstemp, valid) {
  let roomsArray = [];
  for (let i = 0; i < roomstemp.length / 2; i++) {
    let temp = [];
    for (let j = 0; j < 2; j++) {
      let onenum = parseInt(roomstemp.charAt(i * 2 + j), 10);
      if (onenum < 5 && onenum > 0 && j === 0) {
        temp.push(onenum);
      } else if (onenum < 5 && onenum >= 0 && j === 1) {
        temp.push(onenum);
      } else {
        valid = false;
        break;
      }
    }
    roomsArray.push(temp);
  }
  return { roomsArray: roomsArray, valid: valid };
}

export function daysNum(start, end) {
  return Math.floor(moment.duration(moment(end).diff(moment(start))).asDays());
}

export function checkHomePageHotel(hotels, hotel, searchInfo) {
  let data = hotels;
  let noHotel = false;
  let temp = null;
  let temp1 = hotels.slice();
  let index = -1;
  if (hotels && hotels.length > 0) {
    temp1.filter(function(obj) {
      if (
        obj.location.geo.lat === searchInfo.lat.toString() &&
        obj.location.geo.lon === searchInfo.lng.toString()
      ) {
        temp = Object.assign({}, obj);
        index = temp1.indexOf(obj);
      }
    });
    if (index > 0) {
      temp1.splice(index, 1);
      data = temp1.splice(0, 0, temp);
    }
    if (
      hotel &&
      index === -1 &&
      hotel.location.geo.lat === searchInfo.lat.toString() &&
      hotel.location.geo.lon === searchInfo.lng.toString()
    ) {
      noHotel = true;
    } else if (
      hotel &&
      index > -1 &&
      hotel.location.geo.lat === searchInfo.lat.toString() &&
      hotel.location.geo.lon === searchInfo.lng.toString()
    ) {
      noHotel = false;
    }
  }
  return [data, noHotel];
}

export function order(key, order = 'asc') {
  order ? (order = 'asc') : (order = 'desc');
  return function(a, b) {
    if (
      a === null ||
      b === null ||
      ((!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) &&
        key !== 'relevance' &&
        key !== 'exclusive' &&
        key !== 'recomended')
    ) {
      return 0;
    }

    let varA = '';
    let varB = '';

    if (key === 'relevance') {
      varA =
        a.hasOwnProperty('review') &&
        a['review'].hasOwnProperty('rating') &&
        a.hasOwnProperty('stars') &&
        a.hasOwnProperty('location') &&
        a.location.hasOwnProperty('distance')
          ? (parseFloat(a.review.rating) * 1.5 + parseInt(a.stars)) /
            a.location.distance /
            a.total_price
          : 0;
      varB =
        b.hasOwnProperty('review') &&
        b['review'].hasOwnProperty('rating') &&
        b.hasOwnProperty('stars') &&
        b.hasOwnProperty('location') &&
        b.location.hasOwnProperty('distance')
          ? (parseFloat(b.review.rating) * 1.5 + parseInt(b.stars)) /
            b.location.distance /
            b.total_price
          : 0;
    } else if (key === 'exclusive') {
      varA = a.hasOwnProperty('stars') ? parseInt(a.stars) * a.total_price : 0;
      varB = b.hasOwnProperty('stars') ? parseInt(b.stars) * b.total_price : 0;
    } else if (key === 'recomended') {
      varA =
        a.hasOwnProperty('review') &&
        a['review'].hasOwnProperty('rating') &&
        a.hasOwnProperty('location') &&
        a.location.hasOwnProperty('distance')
          ? parseFloat(a.review.rating) / a.total_price / a.location.distance
          : 0;
      varB =
        b.hasOwnProperty('review') &&
        b['review'].hasOwnProperty('rating') &&
        b.hasOwnProperty('location') &&
        b.location.hasOwnProperty('distance')
          ? parseFloat(b.review.rating) / b.total_price / b.location.distance
          : 0;
    } else if (key === 'location') {
      varA =
        a.hasOwnProperty('location') && a.location.hasOwnProperty('distance')
          ? parseFloat(a.location.distance)
          : 100;
      varB =
        b.hasOwnProperty('location') && b.location.hasOwnProperty('distance')
          ? parseFloat(b.location.distance)
          : 100;
    } else if (key === 'review') {
      varA =
        a.hasOwnProperty('review') && a['review'].hasOwnProperty('rating')
          ? parseFloat(a.review.rating)
          : 0;
      varB =
        b.hasOwnProperty('review') && b['review'].hasOwnProperty('rating')
          ? parseFloat(b.review.rating)
          : 0;
    } else {
      varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function getSimilar(
  checksimilar,
  similar,
  hotels,
  hid,
  ckey,
  selectedH
) {
  similar = [];
  let selected = null;
  if (
    selectedH.date.from !== hotels[0].date.from ||
    selectedH.date.to !== hotels[0].date.to
  ) {
    return [similar, false];
  }
  hotels.filter(function(obj) {
    if (obj.hid === hid && obj.c_key === ckey) selected = obj;
  });
  for (let i = 0; i < hotels.length; i++) {
    if (selected !== null && checksimilar) {
      if (hotels[i].hid) {
        if (
          hotels[i].hid !== hid &&
          // (this.props.hotelSelected.review.rating
          //   ? this.props.hotels[i].review.rating >
          //     this.props.hotelSelected.review.rating - 3
          //   : true) &&
          (selected.stars ? hotels[i].stars > selected.stars - 2 : true) &&
          hotels[i].total_price < selected.total_price * 1.5 &&
          hotels[i].total_price > selected.total_price * 0.5 &&
          // VICINITY CHECK
          parseFloat(hotels[i].location.geo.lat) <
            parseFloat(selected.location.geo.lat) + 0.05 &&
          parseFloat(hotels[i].location.geo.lat) >
            parseFloat(selected.location.geo.lat) - 0.05 &&
          parseFloat(hotels[i].location.geo.lon) <
            parseFloat(selected.location.geo.lon) + 0.05 &&
          parseFloat(hotels[i].location.geo.lon) >
            parseFloat(selected.location.geo.lon) - 0.05
        ) {
          similar.push(hotels[i]);
        }
      }
    }
  }
  if (checksimilar && selected !== null) {
    shuffleArray(similar);
    similar.splice(4, similar.length - 4);
  }
  checksimilar = false;
  return [similar, checksimilar];
}

export function setFacilities(hotels, hotelSelected) {
  let hotelsTemp = hotels.slice();
  let aTemp = [];
  let amenities = [
    ['wifi', 0],
    ['wi-fi', 0],
    ['wireles', 0],
    ['tv', 1],
    ['restaurant', 2],
    ['breakfast', 3],
    ['accessib', 4],
    ['swimming pool', 5],
    ['Outdoor pool', 5],
    ['fitness', 6],
    ['gym', 6],
    ['front desk', 7],
    ['no smoking', 8],
    ['no-smoking', 8],
    ['parking', 9],
    ['airport shuttle', 10],
    ['shuttle to airport', 10],
    ['Airport/Hotel/Airport', 10],
    ['heating', 11],
    ['air condition', 12],
    ['air-condition', 12],
    ['A/C & Heat', 12],
    ['pets allowed', 13]
  ];
  // const display = [
  //   'WiFi',
  //   'Internet Available',
  //   'TV',
  //   'Restaurant',
  //   'Breakfast',
  //   'Accessible',
  //   'Swimming Pool',
  //   'Fitness',
  //   'Front Desk',
  //   'No Smoking',
  //   'Parking',
  //   'Airport Shuttle',
  //   'Heating',
  //   'Air Conditioning',
  //   'Pets Allowed'
  // ];

  for (let i = 0; i < hotels.length; i++) {
    aTemp = [];
    for (let hotelProp in hotels[i].facilities) {
      for (let j = 0; j < amenities.length; j++) {
        if (
          typeof hotels[i].facilities[hotelProp] === 'string' &&
          hotels[i].facilities[hotelProp]
            .toLowerCase()
            .includes(amenities[j][0].toLowerCase())
        ) {
          aTemp.push(amenities[j][1]);
        }
      }
    }
    aTemp = aTemp.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
    hotelsTemp[i].facilities_show = aTemp.sort(function(a, b) {
      return a - b;
    });
    delete hotelsTemp[i]['facilities'];

    if (hotelSelected !== 'yes') {
      hotelsTemp[i].desc = shorten(hotelsTemp[i].desc, 200);
      delete hotelsTemp[i]['ld']['c_key'];
      delete hotelsTemp[i]['ld']['sd']['dest'];
      delete hotelsTemp[i]['ld']['sd']['in'];
      delete hotelsTemp[i]['ld']['sd']['out'];
      delete hotelsTemp[i]['ld']['sd']['star_rating'];
      delete hotelsTemp[i]['ld']['sd']['radius'];
      delete hotelsTemp[i]['ld']['sd']['db'];
      delete hotelsTemp[i]['ld']['rid'];
    }
  }
  return hotelsTemp;
}

export function createSearchInfo(lang, curr, sInfo) {
  let temp = Object.assign({}, sInfo);
  if (sInfo.lat !== 0 && sInfo.lng !== 0) {
    let searchUrl =
      '/' +
      lang +
      '/search/' +
      encodeURIComponent(sInfo.city) +
      // sInfo.city.split(' ').join('_') +
      '&loc=' +
      sInfo.lat +
      '&' +
      sInfo.lng +
      '&from=' +
      moment(sInfo.start).format('YYYYMMDD') +
      '&to=' +
      moment(sInfo.end).format('YYYYMMDD') +
      '&rooms=' +
      sInfo.rooms +
      '&curr=' +
      curr +
      '&rad=' +
      sInfo.rad;
    temp.searchUrl = searchUrl;
  }
  return temp;
}
