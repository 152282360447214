import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/min/locales.min';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.toggleCal = this.toggleCal.bind(this);
  }

  handleChangeStart(date) {
    this.props.appActions.setDate(moment(date).format(), 'start');
    // if (date > moment(this.props.end)) {
    this.props.appActions.setDate(
      moment(date)
        .add(1, 'days')
        .format(),
      'end'
    );
    // }
    if (this.props.showModalCal) {
      this.toggleCal(this.props.showModalCal);
    }
  }

  handleChangeEnd(date) {
    this.props.appActions.setDate(moment(date).format(), 'end');
    this.handleHide();
  }

  handleShow() {
    this.props.appActions.modalCal(true, true);
  }

  handleHide() {
    this.props.appActions.modalCal(false, false);
  }

  toggleCal(check) {
    check
      ? this.props.appActions.modalCal(true, !this.props.toggleCal)
      : this.props.appActions.modalCal(true, this.props.toggleCal);
  }

  render() {
    return (
      <div>
        <Col xs={12} className="padding0">
          <button className="btn-datepicker" onClick={() => this.handleShow()}>
            <div className="btn-datepicker-date">
              {this.props.start ? (
                <span>
                  <span className="font-large font-light">
                    {moment(this.props.start).format('D')}
                  </span>
                  &nbsp;
                  {moment(this.props.start).format('MMM')}
                </span>
              ) : (
                'check-in'
              )}
            </div>
            {/* <div className="btn-datepicker-arrow glyphicon glyphicon-menu-right" /> */}
            <FontAwesomeIcon
              icon={faChevronRight}
              className="btn-datepicker-arrow"
            />
            <div className="btn-datepicker-date">
              {this.props.end ? (
                <span>
                  <span className="font-large font-light">
                    {moment(this.props.end).format('D')}
                  </span>
                  &nbsp;
                  {moment(this.props.end).format('MMM')}
                </span>
              ) : (
                'check-out'
              )}
            </div>
          </button>

          <Modal
            show={this.props.showModalCal}
            onHide={() => this.handleHide()}
            dialogClassName="custom-modal modal-cal"
          >
            <Modal.Header closeButton>
              <div className="txt-blue-1 txt18 margin-t4">
                {this.props.toggleCal
                  ? this.props.t('CHECKIN')
                  : this.props.t('CHECKOUT')}
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center date-modal-holder">
                <button
                  className={
                    this.props.toggleCal
                      ? 'btn-date-modal-active'
                      : 'btn-date-modal'
                  }
                  onClick={() => this.toggleCal(!this.props.toggleCal)}
                >
                  {this.props.start ? (
                    <span>
                      <span className="font-large">
                        {moment(this.props.start).format('D')}
                      </span>
                      &nbsp;
                      {moment(this.props.start).format('MMM')}
                    </span>
                  ) : (
                    'check-in'
                  )}
                </button>
                {/* <div className="btn-datepicker-arrow-modal glyphicon glyphicon-menu-right" /> */}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="btn-datepicker-arrow-modal"
                />
                <button
                  className={
                    !this.props.toggleCal
                      ? 'btn-date-modal-active'
                      : 'btn-date-modal'
                  }
                  onClick={() => this.toggleCal(this.props.toggleCal)}
                >
                  {this.props.end ? (
                    <span>
                      <span className="font-large">
                        {moment(this.props.end).format('D')}
                      </span>
                      &nbsp;
                      {moment(this.props.end).format('MMM')}
                    </span>
                  ) : (
                    'check-out'
                  )}
                </button>
                <br />
                {this.props.toggleCal && (
                  <DatePicker
                    className="max-widht-320"
                    minDate={moment()}
                    maxDate={moment().add(329, 'days')}
                    selected={moment(this.props.start)}
                    selectsStart
                    startDate={moment(this.props.start)}
                    endDate={moment(this.props.end)}
                    onChange={this.handleChangeStart}
                    locale={this.props.lang.value}
                    inline
                    placeholderText="check-in"
                  />
                )}
                {!this.props.toggleCal && (
                  <DatePicker
                    minDate={moment(this.props.start).add(1, 'days')}
                    maxDate={moment(this.props.start).add(20, 'days')}
                    selected={moment(this.props.end)}
                    selectsEnd
                    startDate={moment(this.props.start)}
                    endDate={moment(this.props.end)}
                    onChange={this.handleChangeEnd}
                    locale={this.props.lang.value}
                    inline
                    placeholderText="check-out"
                  />
                )}
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </div>
    );
  }
}
export default Calendar;
