import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faGooglePlusG,
  faPinterestP,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import {
  URL_FB,
  URL_TW,
  URL_IN,
  URL_GPLUS,
  URL_PIN,
  URL_INSTAGRAM
} from '../common/const';

// social button links
class SocialButtons extends Component {
  // onClick(e, social) {
  //   e.preventDefault();
  //   alert('Login with: ' + social);
  // }
  render() {
    const social = [
      faFacebookF
      /*faTwitter,
      faLinkedinIn,
      faGooglePlusG,
      faPinterestP,
      faInstagram*/
    ];
    const socialCss = [
      'facebook'
      /*'twitter',
      'linkedin',
      'google',
      'pinterest',
      'instagram'*/
    ];
    const links = [URL_FB];
    return social.map((social, i) => (
      <a
        href={links[i]}
        key={i}
        // onClick={e => this.onClick(e, social)}
        className={
          this.props.white
            ? 'rad0 social-btn-margin-1 btn txt-white txt16px margin-t7'
            : 'rad0 social-btn-margin btn btn-social-icon btn-' + socialCss[i]
        }
        // style={{ fontSize: 14, textAlign:'left' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="margin0">
          <FontAwesomeIcon icon={social} />
        </div>
      </a>
    ));
  }
}

export default SocialButtons;
