import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Routes from '../components/Routes';
import Meta from '../components/Meta';
import { Grid, Row, Col, Image, Glyphicon, Container } from 'react-bootstrap';
// import langs from '../common/langs';
import axios from 'axios';
import HeaderImage from '../components/HeaderImage';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = { htmlString: null };
    this.getExternalFile = this.getExternalFile.bind(this);
    this.isUnmounted = false;
  }
  getExternalFile(lang) {
    axios
      .get('../../locales/info/' + lang + '/privacy.htm')
      .catch(err => {})
      .then(response => {
        if (!this.isUnmounted)
          this.setState({ htmlString: response.data, reload: true });
      });
  }
  componentWillMount() {
    let lang =
      typeof this.props.match.params.language !== 'undefined'
        ? this.props.match.params.language
        : 'en';
    if (lang !== this.props.lang.url) {
      let tempLang = Object.assign({}, this.props.lang);
      tempLang.url = lang;
      this.props.appActions.changeLang(tempLang);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // console.log(nextProps, nextState);
    // console.log(this.props, this.state);
    if (nextProps.lang.value !== this.props.lang.value) {
      return true;
    } else if (nextState.htmlString !== this.state.htmlString) {
      return true;
    } else return false;
  }
  componentWillUnmount() {
    this.isUnmounted = true;
  }
  render() {
    this.getExternalFile(this.props.lang.value);
    let title = this.props.t('META_TITLE_PRIVACY');
    let description = this.props.t('META_DESC_1');
    let keywords = '';
    return (
      <div>
        <HeaderImage {...this.props} image="aboutPage" bar="no" />
        <section className="infoHtml back-white">
          <Routes {...this.props} />
          <Meta
            title={title}
            description={description}
            keywords={keywords}
            {...this.props}
          />
          <Container>
            <h2 className="padding-5 text-left">
              <div className="border-blue-left">
                <div className="margin-b10 txt-blue">Hotelshop.com</div>
                <div className="txt-gray-blue padding-r20 txt18 pull-left margin-b5">
                  A place where best prices meet best hotels!
                </div>
                <div className="clear-all" />
              </div>
            </h2>
            <br />
            <div className="white-holder-1 text-left">
              <div
                dangerouslySetInnerHTML={{ __html: this.state.htmlString }}
              />
            </div>{' '}
          </Container>
          <Row className="back-color-blue padding0 margin0">
            <Col
              xs={12}
              md={{ span: 11, offset: 1 }}
              lg={{ span: 5, offset: 2 }}
              className="text-center about-page-back"
            >
              <br />
              <div className="text-info-large margin-t50 txt-white">
                You are on the right path
              </div>
              <br />
              <div className="txt24 txt-white margin-b50 line-height-1-5 maxw400 margin-lr-auto">
                It takes just 3 easy steps, search, choose and book!
              </div>
              <br />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={5}
              // xsHidden
              // smHidden
              className="d-none d-lg-block padding0 pull-right"
            >
              <Image
                responsive
                src="/images/signs-path.jpg"
                className="float-right"
                height="350"
              />
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

export default withRouter(Privacy);
