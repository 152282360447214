import React from 'react';
import SocialLogin from 'react-social-login';

const Facebook = ({ children, triggerLogin, ...props }) => (
  <div
    className="rad0 btn btn-social-icon btn-facebook"
    onClick={triggerLogin}
    {...props}
  >
    {children}
    <span className={'fa fa-facebook'} />
  </div>
);

export default SocialLogin(Facebook);
