import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import appRed from './appReducer';
import appRedLoc from './appReducerLocal';
import appRedOrder from './appReducerOrder';

export default combineReducers({
  router: routerReducer,
  appRed,
  appRedLoc,
  appRedOrder
});
