import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

// meta data
class Meta extends Component {
  render() {
    return (
      <MetaTags>
        <title>{this.props.title}</title>
        <meta name="description" content={this.props.description} />
        <meta
          name="keywords"
          content={this.props.keywords + this.props.t('META_KEYWORDS_GENERAL')}
        />
        <meta property="og:title" content={this.props.t('META_OG_TITLE')} />
        <meta
          property="og:image"
          content="https://www.hotelshop.com/images/imageHotel.jpg"
        />
        <meta
          property="og:description"
          content={this.props.t('META_OG_DESC')}
        />
        <meta property="og:url" content={this.props.t('META_OG_URL')} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content="/images/logo.png" /> */}
      </MetaTags>
    );
  }
}

export default Meta;
