import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import {
  Box,
  Email,
  Item,
  Span,
  Image,
  A,
  renderEmail
} from 'react-html-email';
import { validateEmail } from '../common/utils';
import {
  URL1,
  URL_FB,
  URL_TW,
  URL_IN,
  URL_GPLUS,
  URL_INSTAGRAM
} from '../common/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const text = {
  fontFamily: 'Tahoma'
};

const text1 = {
  fontSize: 14,
  fontWeight: 'normal',
  color: '#333B48'
};

const text1bold = {
  fontSize: 14,
  fontWeight: 'bold',
  color: '#333B48'
};

const text2 = {
  fontSize: 14,
  fontWeight: 'normal',
  color: '#FFFFFF',
  textTransform: 'uppercase'
};

const text3 = {
  fontSize: 18,
  fontWeight: 'bold',
  color: '#333B48'
};

const text4 = {
  fontSize: 12,
  fontWeight: 'normal',
  color: '#FFFFFF'
};

const text5 = {
  fontSize: 16,
  fontWeight: 'normal',
  color: '#333B48'
};

const header1 = {
  fontSize: 20,
  fontWeight: 'bold',
  color: '#FFFFFF'
};

const width1 = {
  width: '100%'
};

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      status: 'form',
      validationName: null,
      validationEmail: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.createEmail = this.createEmail.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleChange(e, type) {
    if (this.props.subState === 'show')
      this.props.appActions.setSubStatus('working');
    if (type === 'name') {
      this.setState({ name: e.target.value });
      if (e.target.value.length > 0) {
        this.setState({ validationName: 'success' });
      } else {
        this.setState({ validationName: null });
      }
    } else if (type === 'email') {
      this.setState({ email: e.target.value });
      if (e.target.value.length === 0) {
        this.setState({ validationEmail: null });
        return;
      }
      if (validateEmail(e.target.value)) {
        this.setState({ validationEmail: 'success' });
      } else {
        this.setState({ validationEmail: 'error' });
      }
    }
  }
  createEmail() {
    let URL_UNSUBSCRIBE =
      'https://www.hotelshop.com/' +
      (this.props.lang ? this.props.lang.value : 'en') +
      '/unsubscribe/';
    // console.log(URL_UNSUBSCRIBE);
    let email = renderEmail(
      <Email
        style={{
          width: '100%'
        }}
        title="Confirmation Email"
      >
        <Box
          {...text}
          style={{
            width: '600px',
            // borderRadius: '20px',
            backgroundColor: 'rgba(255,255,255,0.7)'
          }}
          align="center"
        >
          <Item align="center">
            <br />
            <A href={URL1}>
              <Image
                data-mc-bar="bar"
                data-mc-baz="baz"
                alt="Hotelshop"
                src="https://www.hotelshop.com/images/logo.png"
                width={210}
                height={40}
              />
            </A>
            <br />
            <br />
          </Item>
          <Item align="center" style={{ backgroundColor: '#33B8FF' }}>
            <br />
            <Span {...header1}>
              Thank you for subscribing to our newsletter!
            </Span>
            <br />
            <br />
          </Item>
          <Item align="center">
            <br />
            <br />
            <Span {...text5}>
              {this.props.t('EMAIL_HELLO')} {this.state.name},
            </Span>
          </Item>
          <Item align="center">
            <br />
            <Span {...text1}>
              You have successfully subscribed to our newsletter. We will keep
              you informed about our incredible deals and discounts.
            </Span>
            <br />
            <br />
            <br />
          </Item>
          <Item align="center" style={{ backgroundColor: '#33B8FF' }}>
            <br />
            <Span {...text4}>
              If you do not wish to receive information about best hotel deals,
              you can <A href={URL_UNSUBSCRIBE}>unsubscribe here</A>.
            </Span>
            <br />
            <br />
          </Item>
          <Item align="center" style={{ color: '#33B8FF' }}>
            <Box align="center">
              <Item align="center">
                <br />
                <Box style={{ width: '50px' }}>
                  <Item style={{ width: '50px', padding: '5px' }}>
                    <A href={URL_FB}>
                      <Image
                        data-mc-bar="bar"
                        data-mc-baz="baz"
                        alt="Hotelshop"
                        src="https://www.hotelshop.com/images/social_fb.png"
                        width={32}
                        height={32}
                      />
                    </A>
                  </Item>
                </Box>
                <Box style={{ width: '50px' }}>
                  <Item style={{ width: '50px', padding: '5px' }}>
                    <A href={URL_TW}>
                      <Image
                        data-mc-bar="bar"
                        data-mc-baz="baz"
                        alt="Hotelshop"
                        src="https://www.hotelshop.com/images/social_twitter.png"
                        width={32}
                        height={32}
                      />
                    </A>
                  </Item>
                </Box>
                <Box style={{ width: '50px' }}>
                  <Item style={{ width: '50px', padding: '5px' }}>
                    <A href={URL_IN}>
                      <Image
                        data-mc-bar="bar"
                        data-mc-baz="baz"
                        alt="Hotelshop"
                        src="https://www.hotelshop.com/images/social_in.png"
                        width={32}
                        height={32}
                      />
                    </A>
                  </Item>
                </Box>
                <Box style={{ width: '50px' }}>
                  <Item style={{ width: '50px', padding: '5px' }}>
                    <A href={URL_GPLUS}>
                      <Image
                        data-mc-bar="bar"
                        data-mc-baz="baz"
                        alt="Hotelshop"
                        src="https://www.hotelshop.com/images/social_gplus.png"
                        width={32}
                        height={32}
                      />
                    </A>
                  </Item>
                </Box>
                <Box style={{ width: '50px' }}>
                  <Item style={{ width: '50px', padding: '5px' }}>
                    <A href={URL_INSTAGRAM}>
                      <Image
                        data-mc-bar="bar"
                        data-mc-baz="baz"
                        alt="Hotelshop"
                        src="https://www.hotelshop.com/images/social_instagram.png"
                        width={32}
                        height={32}
                      />
                    </A>
                  </Item>
                </Box>
              </Item>
            </Box>
          </Item>
        </Box>
      </Email>
    );
    return email;
  }
  submitData(e) {
    e.preventDefault();
    this.props.appActions.loading('subscriberWait');
    let emailMessageHtml = this.createEmail();
    let data = {
      nameFrom: 'Hotelshop.com <support@hotelshop.com>',
      emailTo: this.state.email,
      emailCc: '',
      emailBcc: 'hotelshop@sky-tours.com',
      // emailBcc: "",
      emailSubject:
        'Hello ' +
        this.state.name +
        ' - Recieve the best offers from Hotelshop.com!',
      emailMessageTxt: '',
      emailMessageHtml: emailMessageHtml,
      emailConfirmId: 'subscribe',
      subscribedName: this.state.name,
      subscribedEmail: this.state.email
    };
    axios.post('https://email.hotelshop.com/sendemail', data).then(response => {
      if (response.data.msg === 'success') {
        this.props.appActions.loading('none');
        this.setState({ status: 'yes' });
        setTimeout(() => {
          this.setState({ status: 'form' });
          this.props.appActions.setSubStatus('hidden');
        }, 5000);
        this.setState({ name: '', email: '' });
        // console.log('success');
      } else if (response.data.msg === 'error') {
        this.props.appActions.loading('none');
        this.setState({ status: 'no' });
        setTimeout(() => this.setState({ status: 'form' }), 2000);
        // console.log('error');
      }
    });
  }
  render() {
    return (
      <div>
        {this.state.status === 'form' && (
          <Form inline>
            <Row>
              <Col xs={9} sm={5} lg={5}>
                <Form.Group
                  controlId="formInlineName"
                  // validationState={this.state.validationName}
                >
                  <Form.Control
                    isValid={this.state.validationName === 'success'}
                    isInvalid={this.state.validationName === 'error'}
                    required
                    type="text"
                    className="box-style1"
                    placeholder={this.props.t('FIRST_NAME')}
                    onChange={e => this.handleChange(e, 'name')}
                    onClick={e => this.handleChange(e, 'clicked')}
                    value={this.state.name}
                  />
                </Form.Group>{' '}
              </Col>
              <Col xs={9} sm={5} lg={5}>
                <Form.Group
                  controlId="formInlineEmail"
                  // validationState={this.state.validationEmail}
                >
                  <Form.Control
                    isValid={this.state.validationEmail === 'success'}
                    isInvalid={this.state.validationEmail === 'error'}
                    required
                    type="email"
                    className="box-style1"
                    placeholder={this.props.t('EMAIL')}
                    onChange={e => this.handleChange(e, 'email')}
                    onClick={e => this.handleChange(e, 'clicked')}
                    value={this.state.email}
                  />
                </Form.Group>{' '}
              </Col>
              <Col xs={3} sm={2} lg={1}>
                {this.props.loading === 'subscriberWait' ? (
                  <div className="loader2" />
                ) : (
                  <button
                    onClick={e => this.submitData(e)}
                    className={
                      this.state.validationName === 'success' &&
                      this.state.validationEmail === 'success'
                        ? 'btn-50 margin0 padding-tb5'
                        : 'btn-50 margin0 padding-tb5 transparent-75'
                    }
                    disabled={
                      this.state.validationName !== 'success' ||
                      this.state.validationEmail !== 'success'
                    }
                  >
                    {/* <Glyphicon glyph="menu-right" className="txt28" /> */}
                    <FontAwesomeIcon icon={faChevronRight} className="txt28" />
                  </button>
                )}
              </Col>
            </Row>
          </Form>
        )}
        {this.state.status === 'yes' && (
          <Col xs={12} className="height-min50 padding-tb10">
            <h3 className="text-left margin0 padding-lr15 padding-tb0">
              <strong>Thank you!</strong>
            </h3>
          </Col>
        )}
        {this.state.status === 'no' && (
          <Col xs={12} className="height-min50 padding-tb10">
            <h3 className="text-left margin0 padding-lr15 padding-tb0">
              <strong>Please, try again!</strong>
            </h3>
          </Col>
        )}
      </div>
    );
  }
}

export default Subscribe;
