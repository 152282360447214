import React, { Component } from 'react';
// import { Modal, Glyphicon } from 'react-bootstrap';
class Hotels extends Component {
  // constructor(props) {
  //   super(props);
  // }
  closeModal() {
    // let msg = this.props.msg;
    this.props.appActions.isError(null);
    // if (msg === 'Sold Out, try other kind of room or Hotel') {
    //   let url = window.location.pathname.slice(5);
    //   this.props.appActions.setRouteInfo('/hotel' + url);
    // }
  }
  render() {
    const { t, i18n } = this.props;
    if (!this.props.error || this.props.msg === null) {
      return null;
    } else {
      return (
        <div>
          {/* <Modal show={this.props.msg !== null}>
            <Modal.Body className="text-center txt-error">
              <img alt="logo" className="mW220" src="/images/logo.png" />
              <br />
              <h1 className="text-center">
                <Glyphicon glyph="exclamation-sign text-center" />
              </h1>
              <h2>{this.props.msg}</h2>
              <br />
              <button onClick={() => this.closeModal()}>
                {this.props.t('CLOSE_TEXT')}
              </button>
            </Modal.Body>
          </Modal> */}
          {/* <Alert Variant="danger">
          <strong>{t('FILE_ERROR')}</strong> {this.props.msg}
        </Alert> */}
        </div>
      );
    }
  }
}
export default Hotels;
