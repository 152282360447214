import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import querySring from 'query-string';
// import Steps from './Steps';
import asyncComponent from './AsyncComponent';
//use AsyncComponent for Code Splitting
const AsyncNotFound = asyncComponent(() => import('../singlePages/NotFound'));
const AsyncAbout = asyncComponent(() => import('../singlePages/About'));
const AsyncPrivacy = asyncComponent(() => import('../singlePages/Privacy'));
const AsyncTerms = asyncComponent(() => import('../singlePages/Terms'));
const AsyncCookie = asyncComponent(() => import('../singlePages/Cookie'));
const AsyncConfirmation = asyncComponent(() => import('./Confirmation1'));
const AsyncConfirmationFinal = asyncComponent(() =>
  import('./ConfirmationFinal')
);
const AsyncContentSearchResults = asyncComponent(() =>
  import('./ContentSearchResults')
);
const AsyncContentHome = asyncComponent(() => import('./ContentHome'));
const AsyncContentHotel = asyncComponent(() => import('./ContentHotel'));
const AsyncCity = asyncComponent(() => import('./City'));
const AsyncPromoCity = asyncComponent(() => import('./PromoCity'));
const AsyncSubscriptionRemove = asyncComponent(() =>
  import('./SubscriptionRemove')
);
// const AsyncBookHotel = asyncComponent(() => import('./BookHotel'));

// render all routes based on their paths
// also using code splitting
class RoutesRender extends Component {
  constructor(props) {
    super(props);
    this.redirectUrl = '';
  }
  render() {
    switch (window.location.search) {
      case '?what=terms':
        window.location.pathname = '/info/terms';
        window.location.search = '';
        break;
      default:
        break;
    }
    return (
      <BrowserRouter>
        <div>
          <Header {...this.props} />
          <div>
            {/* <section>
                      <Steps {...this.props} />
                  </section> */}
            <Switch>
              <Route
                path="/:language/search/:city&loc=:lat&:lng&from=:start&to=:end&rooms=:rooms&curr=:currency&rad=:radius&country=:country"
                render={() => <AsyncContentSearchResults {...this.props} />}
              />
              {/* <Route
                path="/:language/search"
                render={() => <AsyncContentSearchResults {...this.props} />}
              /> */}
              <Route
                exact
                path="/:language/hotel/:city&loc=:lat&:lng&from=:start&to=:end&rooms=:rooms&curr=:currency&rad=:radius&hid=:hid&ckey=:ckey"
                render={() => <AsyncContentHotel {...this.props} />}
              />
              <Route
                exact
                path="/:language/hotel/:hotel/:city&loc=:lat&:lng&from=:start&to=:end&rooms=:rooms&curr=:currency&rad=:radius&hid=:hid&ckey=:ckey"
                render={() => <AsyncContentHotel {...this.props} />}
              />
              {/* <Route
                exact
                path="/:language/hotel"
                render={() => <AsyncContentHotel {...this.props} />}
              /> */}
              <Route
                exact
                path="/:language/promo/:type/:city"
                render={() => (
                  <section className="back-white">
                    <AsyncPromoCity {...this.props} />
                  </section>
                )}
              />
              {/* CITY LANDING PAGES START */}
              <Route
                exact
                path="/:language/city/:cityid&:ct/:city"
                render={() => (
                  <section className="back-white">
                    {this.props.gMapLoaded && <AsyncCity {...this.props} />}
                  </section>
                )}
              />
              <Route
                exact
                path="/:language/city/:code/:city"
                render={() => {
                  return <Redirect to={'/' + this.props.lang.value} />;
                }}
              />
              <Route
                exact
                path="/city/:code/:city"
                render={() => {
                  return <Redirect to={'/' + this.props.lang.value} />;
                }}
              />
              {/* CITY LANDING PAGES END */}
              <Route
                exact
                path="/:language/unsubscribe/"
                render={() => (
                  <section className="back-white">
                    <AsyncSubscriptionRemove {...this.props} />
                  </section>
                )}
              />
              {/* <Route
                exact
                path="/book/:city&loc=:lat&:lng&from=:start&to=:end&rooms=:rooms&lang=:language&curr=:currency&rad=:radius&hid=:hid&ckey=:ckey"
                component={AsyncBookHotel}
              /> */}
              <Route
                exact
                path="/:language/info/about"
                render={() => (
                  <section className="back-white">
                    <AsyncAbout {...this.props} />
                  </section>
                )}
              />
              <Route
                exact
                path="/:language/about.htm"
                render={() => (
                  <Redirect to={'/' + this.props.lang.value + '/info/about'} />
                )}
              />
              <Route
                exact
                path="/:language/info/privacy"
                render={() => (
                  <section className="back-white">
                    <AsyncPrivacy {...this.props} />
                  </section>
                )}
              />
              <Route
                exact
                path="/:language/info/terms"
                render={() => (
                  <section className="back-white">
                    <AsyncTerms {...this.props} />
                  </section>
                )}
              />
              <Route
                exact
                path="/info.php"
                render={() => (
                  <Redirect to={'/' + this.props.lang.value + '/info/terms'} />
                )}
              />
              <Route
                exact
                path="/:language/info/cookies"
                render={() => (
                  <section className="back-white">
                    <AsyncCookie {...this.props} />
                  </section>
                )}
              />
              <Route
                path="/confirmation"
                render={() => <AsyncConfirmation {...this.props} />}
              />
              <Route
                exact
                path="/:language/order/id=:confirmationId&rl=:recordLocator"
                render={() => <AsyncConfirmationFinal {...this.props} />}
              />
              <Route path="/images/" />
              <Route
                exact
                path="/page=404"
                render={() => <AsyncNotFound {...this.props} />}
              />
              <Route
                exact
                path="/:language/page=404"
                render={() => <AsyncNotFound {...this.props} />}
              />
              <Route
                exact
                path="/"
                render={() => <AsyncContentHome {...this.props} />}
              />
              <Route
                exact
                path="/:language"
                render={() => <AsyncContentHome {...this.props} />}
              />
              <Route
                render={() => {
                  this.props.appActions.setRedirectUrl(
                    window.location.pathname
                  );
                  return (
                    <Redirect to={'/' + this.props.lang.value + '/page=404/'} />
                  );
                }}
              />
            </Switch>
          </div>
          <Footer {...this.props} />
        </div>
      </BrowserRouter>
    );
  }
}

export default RoutesRender;
