import React, { Component } from 'react';
import NumberRooms from './NumberRooms';
import Autocomplete from './Autocomplete';
import Calendar from './Calendar';
import NationailtySelect from './NationalitySelect';
import { totalAdultsChildren } from '../common/utils';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import { SEARCH_RADIUS, SERVICES } from '../common/const';
import { Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// main searchbar component
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: '--'
    };
    this.onClick = this.onClick.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  onClick(e) {
    if (!this.props.locVisited) this.props.appActions.setShowLocal(true);
    else
      this.props.appActions.fetchData(
        this.props.city,
        this.props.start,
        this.props.end,
        this.props.curr.value,
        this.props.lat,
        this.props.lng,
        this.props.lang.value,
        this.props.rooms.length,
        this.props.rooms,
        totalAdultsChildren(this.props.rooms),
        this.props.radius,
        false,
        false,
        this.props.citiesList,
        false,
        'no_name',
        this.state.selectedService,
        this.props.country.code
      );
  }

  onServiceSelect = () => {};

  // componentWillMount() {
  //   if (this.props.showBar !== 'no')
  //     this.props.appActions.setShowBar('no', 'active');
  // }

  onActive() {
    if (this.props.showBar !== 'yes')
      this.props.appActions.setShowBar('yes', 'active');
  }
  render() {
    // console.log(this.state.selectedService);
    const { t, i18n } = this.props;
    return (
      <Element name="autocomplete">
        <div className="z110">
          <Col xs={12} className="searchbar-center">
            <Col xs={12} className="searchbar-holder">
              <Row className="margin0">
                <Col
                  lg={3}
                  md={12}
                  sm={this.props.activeBar !== 'no' ? 12 : 9}
                  xs={12}
                  className="padding0 padding-tb10"
                >
                  <div
                    className="search-wrapper"
                    onClick={() => this.onActive()}
                  >
                    {this.props.gMapLoaded && <Autocomplete {...this.props} />}
                  </div>
                </Col>

                {this.props.activeBar === 'no' && (
                  <Col xs={12} sm={3} md={2} className="padding0 padding-tb10">
                    <button className="btn-search" onClick={this.onActive}>
                      {t('BUTTON_SEARCH')}
                    </button>
                  </Col>
                )}
                <Col>
                  {this.props.activeBar !== 'no' && (
                    <Row>
                      <Col
                        lg={4}
                        md={12}
                        xs={12}
                        className="padding0 padding-tb10"
                      >
                        <div className="search-wrapper">
                          <Calendar {...this.props} />
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        md={6}
                        xs={12}
                        className="padding0 padding-tb10"
                      >
                        <div className="search-wrapper">
                          <NumberRooms {...this.props} />
                        </div>
                      </Col>
                      <Col
                        lg={4}
                        md={6}
                        xs={12}
                        className="padding0 padding-tb10"
                      >
                        <Form>
                          <NationailtySelect
                            {...this.props}
                            selectDropdown={true}
                          />
                        </Form>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              {this.props.godMode && (
                <Row>
                  <Col
                    xs={12}
                    md={{ offset: 7, span: 5 }}
                    className="text-left padding-tb5"
                  >
                    <Form>
                      <FormGroup
                        controlId="service-select"
                        className="txt-blue"
                      >
                        <div className="padding-tb5">select service</div>
                        <FormControl
                          as="select"
                          className="rad0"
                          onChange={e =>
                            this.setState({ selectedService: e.target.value })
                          }
                        >
                          <option value={'--'}>--</option>
                          {SERVICES.map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </FormControl>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              )}
            </Col>{' '}
            <Col
              lg={2}
              md={3}
              sm={12}
              xs={12}
              className="padding0 padding-tb10 float-right"
            >
              <button
                className="btn-submit btn-search"
                onClick={this.onClick}
                disabled={this.props.lat === 0}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </Col>
          </Col>
        </div>
      </Element>
    );
  }
}

export default SearchBar;
