import { Component } from 'react';
// import ReactGA from 'react-ga';
import moment from 'moment';
import 'moment/min/locales.min';
import { totalAdultsChildren } from '../common/utils';
import { SEARCH_RADIUS } from '../common/const';
import { animateScroll as scroll } from 'react-scroll';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.setRoute = this.setRoute.bind(this);
  }

  setRoute(route, replace = false) {
    if (!replace) this.props.history.push(route);
    else if (replace) this.props.history.replace(route);
    this.props.appActions.setRouteInfo('none');
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.routes !== this.props.routes && nextProps.routes !== 'none')
      return true;
    else return false;
  }

  componentWillReceiveProps() {
    if (
      typeof this.props.routes !== 'undefined' &&
      this.props.routes !== null &&
      this.props.history
    ) {
      // this.manageSearchBar(window.location);
      // ReactGA.initialize('UA-127855409-1');
      // ReactGA.pageview(window.location.pathname);
      switch (this.props.routes) {
        case 'search':
          this.setRoute(
            '/' +
              this.props.lang.value +
              '/search/' +
              encodeURIComponent(this.props.searchInfo.city) +
              // this.props.searchInfo.city.split(' ').join('_') +
              '&loc=' +
              this.props.searchInfo.lat +
              '&' +
              this.props.searchInfo.lng +
              '&from=' +
              moment(this.props.searchInfo.start).format('YYYYMMDD') +
              '&to=' +
              moment(this.props.searchInfo.end).format('YYYYMMDD') +
              '&rooms=' +
              totalAdultsChildren(this.props.searchInfo.rooms) +
              '&curr=' +
              this.props.curr.value +
              '&rad=' +
              this.props.radius +
              '&country=' +
              this.props.country.code
          );
          if (this.props.hotelsMap) this.props.appActions.showHotelsMap(false);
          scroll.scrollToTop();
          break;
        case 'searchReplace':
          this.setRoute(
            '/' +
              this.props.lang.value +
              '/search/' +
              encodeURIComponent(this.props.searchInfo.city) +
              // this.props.searchInfo.city.split(' ').join('_') +
              '&loc=' +
              this.props.searchInfo.lat +
              '&' +
              this.props.searchInfo.lng +
              '&from=' +
              moment(this.props.searchInfo.start).format('YYYYMMDD') +
              '&to=' +
              moment(this.props.searchInfo.end).format('YYYYMMDD') +
              '&rooms=' +
              totalAdultsChildren(this.props.searchInfo.rooms) +
              '&curr=' +
              this.props.curr.value +
              '&rad=' +
              this.props.radius +
              '&country=' +
              this.props.country.code,
            true
          );
          if (this.props.hotelsMap) this.props.appActions.showHotelsMap(false);
          scroll.scrollToTop();
          break;
        case 'home':
          this.props.lang.value === 'en'
            ? this.setRoute('/')
            : this.setRoute('/' + this.props.lang.value);
          scroll.scrollToTop();
          break;
        case 'homeReplace':
          this.props.lang.value === 'en'
            ? this.setRoute('/', true)
            : this.setRoute('/' + this.props.lang.value, true);
          scroll.scrollToTop();
          break;
        // case 'confirmation':
        //   this.props.appActions.setRouteInfo('none');
        //   this.props.history.push('/confirmation');
        //   scroll.scrollToTop();
        //   break;
        case 'terms':
          this.setRoute('/' + this.props.lang.value + '/info/terms');
          scroll.scrollToTop();
          break;
        case 'privacy':
          this.setRoute('/' + this.props.lang.value + '/info/privacy');
          scroll.scrollToTop();
          break;
        case 'cookies':
          this.setRoute('/' + this.props.lang.value + '/info/cookies');
          scroll.scrollToTop();
          break;
        case 'about':
          this.props.history.push('/' + this.props.lang.value + '/info/about');
          scroll.scrollToTop();
          break;
        case 'unsubscribe':
          this.props.history.push('/' + this.props.lang.value + '/unsubscribe');
          break;
        case '/page=404':
          this.setRoute('/' + this.props.lang.value + '/page=404');
          scroll.scrollToTop();
          break;
        default:
          if (this.props.routes.includes('/hotel/')) {
            if (this.props.hotelsMap)
              this.props.appActions.showHotelsMap(false);
            let route = this.props.routes;
            if (this.props.notFirst) {
              this.setRoute(route, true);
            } else this.setRoute(route);
            //scroll.scrollToTop();
            break;
          }
          if (this.props.routes.includes('/city/')) {
            let route = this.props.routes;
            this.setRoute(route);
            scroll.scrollToTop();
            break;
          }
          if (this.props.routes.includes('/promo/')) {
            let route = this.props.routes;
            this.setRoute(route);
            scroll.scrollToTop();
            break;
          }
          if (this.props.routes.includes('/search/')) {
            let route = this.props.routes;
            this.setRoute(route);
            break;
          }
          if (this.props.routes.includes('/unsubscribe')) {
            let route = this.props.routes;
            this.setRoute(route);
            scroll.scrollToTop();
            break;
          }
          if (this.props.routes.includes('/book')) {
            let route = this.props.routes;
            this.setRoute(route);
            scroll.scrollToTop();
            break;
          }
          if (this.props.routes.includes('/order/')) {
            let route = this.props.routes;
            this.setRoute(route);
            scroll.scrollToTop();
            break;
          }
          this.props.appActions.setRouteInfo('none');
          break;
      }
    }
  }

  render() {
    return null;
  }
}
export default Routes;
