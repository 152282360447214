export const G_A_KEY = 'AIzaSyBg7zlF_aL4E131UIQ40uwPjLS_rrKigm8';
// export const G_A_KEY = 'AIzaSyDYDA2C3KSTeGHv9-BG1Grd1caQrYOI-L0'; // testing API
export const SEARCH_RADIUS = 10;
export const SEARCH_RADIUS_1 = 1;
export const HOME_PAGE_SECTIONS = 4;
export const MAX_GUESTS = 4;
export const PARTNERS = [
  'arc',
  'asta',
  'ustravel',
  'srf',
  'iata',
  'drv',
  'tripadvisor'
];
export const PER_PAGE = 10;
export const PAGES = 5;

export const URL1 = 'https://www.hotelshop.com';

export const HOTEL_API = 'https://hotelapi.militaryfares.com/';
// 'https://devel12.hotelapi.sky-tours.com/'
// window.location.hostname.includes('devel') &&
// !window.location.hostname.includes('devel5')
//   ? 'https://' +
//     window.location.hostname.split('.')[0] +
//     '.hotelapi.sky-tours.com/'
//   : 'https://hotelapi.sky-tours.com/';
//
// AIzaSyBg7zlF_aL4E131UIQ40uwPjLS_rrKigm8

export const URL_FB = 'https://www.facebook.com/HotelShopTravel';
export const URL_TW = '';
export const URL_IN = '';
export const URL_GPLUS = '';
export const URL_PIN = '';
export const URL_INSTAGRAM = '';

export const PROPERTY_TYPES = [
  ['hotel'],
  ['apartment', 'appartment'],
  ['guest accommodation'],
  ['bed and breakfast', 'b&b'],
  ['hostel', 'hostal']
];
export const PROPERTY_TYPES_DISPLAY = [
  'ACCOMODATION_HOTEL',
  'ACCOMODATION_APARTMENT',
  'ACCOMODATION_GUEST_HOUSE',
  'ACCOMODATION_BED_BREAKFAST',
  'ACCOMODATION_HOSTEL'
];
export const RADIUS_FILTER = [2, 5, 10, 15];

export const FILTERS_LENGTH = 8;
// export const DEFAULT_FILTERS = [
//   { msg: '' },
//   { name: '' },
//   { stars: [] },
//   { rating: -1 },
//   { facilities: [] },
//   { price: [0, 0] },
//   { type: [] },
//   { offer: false, hasOffers: 0 }
// ];
// list of cities for trending destinations on homepage
// export const DESTINATIONS = [
//   {
//     name: 'madrid',
//     city: 'Madrid',
//     country: 'Spain',
//     lat: 40.4167754,
//     lon: -3.7037901999999576
//   },
//   {
//     name: 'paris',
//     city: 'Paris',
//     country: 'France',
//     lat: 48.856614,
//     lon: 2.352222
//   },
//   {
//     name: 'ibiza',
//     city: 'Ibiza',
//     country: 'Spain',
//     lat: 38.908735,
//     lon: 1.428901
//   },
//   {
//     name: 'mallorca',
//     city: 'Mallorca',
//     country: 'Spain',
//     lat: 39.5696,
//     lon: 2.65016
//   },
//   {
//     name: 'amsterdam',
//     city: 'Amsterdam',
//     country: 'Netherlands',
//     lat: 52.370216,
//     lon: 4.895168
//   },
//   {
//     name: 'berlin',
//     city: 'Berlin',
//     country: 'Germany',
//     lat: 52.520007,
//     lon: 13.404954
//   },
//   {
//     name: 'egipto',
//     city: 'Cairo',
//     country: 'Egypt',
//     lat: 30.04442,
//     lon: 31.235712
//   },
//   {
//     name: 'china',
//     city: 'Beijing',
//     country: 'China',
//     lat: 39.9042,
//     lon: 116.407396
//   },
//   {
//     name: 'india',
//     city: 'New Delhi',
//     country: 'India',
//     lat: 28.623753,
//     lon: 77.207266
//   },
//   {
//     name: 'kenia',
//     city: 'Nairobi',
//     country: 'Kenya',
//     lat: -1.292066,
//     lon: 36.821946
//   },
//   {
//     name: 'barcelona',
//     city: 'Barcelona',
//     country: 'Spain',
//     lat: 41.385064,
//     lon: 2.173403
//   },
//   {
//     name: 'brasil',
//     city: 'São Paulo',
//     country: 'Brazil',
//     lat: -23.5505199,
//     lon: -46.63330939999997
//   }
// ];

// export const BACKGROUNDS = [
//   'amsterdam',
//   'athens',
//   'barcelona',
//   'berlin',
//   'brasil',
//   'china',
//   'egipto',
//   'hong_kong',
//   'ibiza',
//   'india',
//   'kenia',
//   'kyoto',
//   'los_angeles',
//   'madrid',
//   'mallorca',
//   'melbourne',
//   'newyork',
//   'paris',
//   'sidney',
//   'stockholm',
//   'thessaloniki'
// ];

// list of cities for the hotels on homepage
export const CITY_HOTELS = [
  'Athens',
  'Barcelona',
  'Buenos Aires',
  'Las Vegas',
  'New Orleans',
  'New York',
  'Paris',
  'Rio De Janeiro',
  'Rome',
  'Venice'
];

export const SERVICES = [
  'aic',
  'anixe',
  'DBhotels',
  'Grn',
  'hotusa',
  'juniper',
  'priceline',
  'pricelineCug',
  'travayoo',
  'travelfusion'
];
