import initialState from './initialState';
import {
  LOADING,
  FETCH_DATA,
  SET_CITY,
  SET_CITY_EN,
  SET_CITY_LOCAL,
  SET_CITY_HOME,
  RECEIVE_DATA,
  RECEIVE_TA_DATA,
  CHANGE_PAGE,
  SEARCH_TIME,
  SUBSCRIPTION_STATUS,
  G_MAP_LOADED,
  ERROR,
  CLEAN,
  ORDER,
  FILTER,
  FILTERS,
  VISITED,
  FAVS,
  SEARCH_INFO,
  ROUTE_INFO,
  MODAL_INFO,
  SHOW_HOTELSMAP,
  SHOW_MAP_INFO,
  SHOW_MAP_INFO_HOVER,
  SET_END_DATE,
  SET_START_DATE,
  ROOMS_INFO,
  BOOK_INFO,
  GUESTS_INFO,
  GUESTS_CONTACT,
  VALIDATION,
  IS_COMPLETE,
  FILTER_MODAL,
  FILTER_MAP,
  SHOW_ROOMS,
  SHOW_BAR,
  ACTIVE_BAR,
  MODAL_CALENDAR,
  SET_COORDINATES,
  CLEAR_FILTER,
  SETUSERINFO,
  ACCEPTCOOKIES,
  HOTEL_INFO,
  // CHANGE_LANG,
  // CHANGE_CURR,
  EXCHANGE_RATE,
  PACK_SELECTED,
  PRICE_VALIDATION,
  STORE_RESERVATION,
  SELECT_HOTEL,
  CITIES_LIST,
  HOTELS_HOME,
  COMPLETED,
  REDIRECT_URL,
  SET_RADIUS,
  SET_GOD_MODE
} from '../actions/allActions';

export default function appRed(state = initialState, action) {
  // let newState = {};
  // let newSate = {};
  switch (action.type) {
    case 'persist/REHYDRATE':
      let newSate = {};
      typeof action.payload != 'undefined'
        ? (newSate = { ...action.payload.appRed })
        : (newSate = state);
      return newSate;
    case SELECT_HOTEL:
      return { ...state, hotelSelected: action.hotelSelected };
    case COMPLETED:
      return { ...state, completed: action.completed };
    case PACK_SELECTED:
      // return { ...state, packSelected: {...state.packSelected, [action.roomNumber]:action.packSelected }, loading: false };
      return {
        ...state,
        packSelected: action.packSelected
          ? !action.roomNumber
            ? action.packSelected
            : {
                ...state.packSelected,
                [action.roomNumber]: action.packSelected
              }
          : null,
        loading: false
      };
    case CHANGE_PAGE:
      return { ...state, page: action.page, loading: false };
    case PRICE_VALIDATION:
      return {
        ...state,
        priceValidation: action.priceValidation
          ? !action.roomNumber
            ? action.priceValidation
            : {
                ...state.priceValidation,
                [action.roomNumber]: action.priceValidation
              }
          : null,
        loading: false
      };
    case CLEAN:
      return initialState;
    case STORE_RESERVATION:
      return { ...state, storeReservation: action.storeReservation };
    case FETCH_DATA:
      return {
        ...state,
        error: false,
        filtered: [],
        hotelInfo: {},
        hotels: []
      };
    // case CHANGE_LANG:
    //   return { ...state, lang: action.lang };
    // case CHANGE_CURR:
    //   return { ...state, curr: action.curr };
    case G_MAP_LOADED:
      return { ...state, gMapLoaded: action.gMapLoaded };
    case EXCHANGE_RATE:
      return { ...state, exchangeRate: action.exchangeRate };
    case RECEIVE_DATA:
      return {
        ...state,
        hotels: action.hotels,
        error: false,
        filtered: [],
        hotelInfo: {},
        loading: false,
        msg: null
      };
    case RECEIVE_TA_DATA:
      return { ...state, tripAdvisor: action.tripAdvisor };
    case ERROR:
      return { ...state, error: true, loading: false, msg: action.msg };
    case LOADING:
      return { ...state, loading: action.loading, error: false };
    case ORDER:
      return { ...state, order: action.order };
    case SET_RADIUS:
      return { ...state, radius: action.radius };
    case CLEAR_FILTER:
      return { ...state, filtered: [], radius: 10 };
    case FILTER:
      return { ...state, filtered: action.filtered };
    case FILTER_MODAL:
      return { ...state, filterModal: action.filterModal };
    case FILTER_MAP:
      return { ...state, filterMap: action.filterMap };
    case VISITED:
      return { ...state, visited: action.visited };
    case FAVS:
      return { ...state, favs: action.favs };
    case SEARCH_INFO:
      return { ...state, searchInfo: action.searchInfo };
    case BOOK_INFO:
      return { ...state, bookInfo: action.bookInfo };
    case SEARCH_TIME:
      return { ...state, searchTime: action.searchTime };
    case SUBSCRIPTION_STATUS:
      return { ...state, subStatus: action.subStatus };
    case GUESTS_INFO:
      return { ...state, guests: action.guests };
    case GUESTS_CONTACT:
      return { ...state, guestsContact: action.guestsContact };
    case VALIDATION:
      return { ...state, valid: action.valid };
    case IS_COMPLETE:
      return { ...state, complete: action.complete };
    case SHOW_BAR:
      return { ...state, showBar: action.showBar };
    case ACTIVE_BAR:
      return { ...state, activeBar: action.activeBar };
    case ROUTE_INFO:
      return { ...state, routes: action.routes };
    case CITIES_LIST:
      return { ...state, citiesList: action.citiesList };
    case HOTELS_HOME:
      return { ...state, hotelsHome: action.hotelsHome };
    case MODAL_INFO:
      return { ...state, modal: action.modal };
    case FILTERS:
      return { ...state, filters: action.filters };
    case SHOW_HOTELSMAP:
      return { ...state, hotelsMap: action.hotelsMap, loading: false };
    case SHOW_MAP_INFO:
      return { ...state, mapInfo: action.mapInfo };
    case SHOW_MAP_INFO_HOVER:
      return { ...state, mapInfoHover: action.mapInfoHover };
    case SET_END_DATE:
      return { ...state, end: action.date };
    case SET_START_DATE:
      return { ...state, start: action.date };
    case SET_COORDINATES:
      return { ...state, lat: action.lat, lng: action.lng, city: action.city };
    case SET_CITY:
      return { ...state, city: action.city };
    case SET_CITY_EN:
      return { ...state, cityEn: action.cityEn };
    case SET_CITY_LOCAL:
      return { ...state, cityLocal: action.cityLocal };
    case SET_CITY_HOME:
      return { ...state, cityHome: action.cityHome };
    case ROOMS_INFO:
      return { ...state, rooms: action.rooms };
    case REDIRECT_URL:
      return { ...state, redirectUrl: action.redirectUrl };
    case HOTEL_INFO:
      newSate = { ...state, hotelInfo: action.hotelInfo };
      return newSate;
    case SHOW_ROOMS:
      return {
        ...state,
        showRooms: action.showRooms,
        activeRoom: action.activeRoom
      };
    case MODAL_CALENDAR:
      return {
        ...state,
        showModalCal: action.showModalCal,
        toggleCal: action.toggleCal
      };
    case SETUSERINFO:
      let parameter = Object.keys(action.param)[0];
      let userInfoFull = state.userInfo;
      userInfoFull[parameter] = action.param[parameter];

      return {
        ...state,
        userInfo: userInfoFull
      };
    case ACCEPTCOOKIES:
      return {
        ...state,
        cookies: action.param
      };
    case SET_GOD_MODE:
      return { ...state, godMode: action.godMode };
    default:
      return state;
  }
}
