let langs = [
  {
    value: 'en',
    label: 'United States',
    currency: 'USD',
    name: 'English',
    native: 'English'
  },
  {
    value: 'es',
    label: 'España',
    currency: 'EUR',
    name: 'Spanish',
    native: 'español'
  },
  {
    value: 'de',
    label: 'Deutschland',
    currency: 'EUR',
    name: 'German',
    native: 'Deutsch'
  },
  {
    value: 'ru',
    label: 'Россия',
    currency: 'RUB',
    name: 'Russian',
    native: 'русский язык'
  }
  // { value: 'fr', label: 'France', currency: 'EUR', name: 'French', native:'français' },
  // { value: 'se', label: 'Sverige', currency: 'SEK'},
  // { value: 'ca', label: 'Canada', currency: 'CAD'},

  // { value: 'ct', label: 'Andorra', currency: 'EUR' },
  // { value: 'ar', label: 'Argentina', currency: 'ARS' },
  // { value: 'at', label: 'Österreich', currency: 'EUR' },
  // { value: 'au', label: 'Australia', currency: 'AUD' },
  // { value: 'be', label: 'België', currency: 'EUR' },
  // { value: 'bo', label: 'Bolivia', currency: 'BOB' },
  // { value: 'br', label: 'Brasil', currency: 'BRL' },
  // { value: 'bg', label: 'България', currency: 'BGN' },
  // { value: 'ca', label: 'Canada', currency: 'CAD' },
  // { value: 'qb', label: 'Québec (CA)', currency: 'CAD' },
  // { value: 'cl', label: 'Chile', currency: 'CLP' },
  // { value: 'cn', label: '中华人民共和国', currency: 'CNY' },
  // { value: 'co', label: 'Colombia', currency: 'COP' },
  // { value: 'cr', label: 'Costa Rica', currency: 'CRC' },
  // { value: 'cz', label: 'Česká republika', currency: 'CZK' },
  // { value: 'dk', label: 'Danmark', currency: 'DKK' },
  // { value: 'ec', label: 'Ecuador', currency: 'USD' },
  // { value: 'ee', label: 'Eesti Vabariik', currency: 'EUR' },
  // { value: 'fi', label: 'Suomi', currency: 'EUR' },
  // { value: 'de', label: 'Deutschland', currency: 'EUR' },
  // { value: 'do', label: 'Dominican Republic', currency: 'DOP' },
  // { value: 'gr', label: 'Ελλάδα', currency: 'EUR' },
  // { value: 'sv', label: 'El Salvador', currency: 'USD' },
  // { value: 'gt', label: 'Guatemala', currency: 'GTQ' },
  // { value: 'hk', label: '香港', currency: 'HKD' },
  // { value: 'hn', label: 'Honduras', currency: 'HNL' },
  // { value: 'hu', label: 'Magyarország', currency: 'HUF' },
  // { value: 'in', label: 'India', currency: 'INR' },
  // { value: 'id', label: 'Indonesia', currency: 'IDR' },
  // { value: 'ir', label: 'ایران', currency: 'IRR' },
  // { value: 'ie', label: 'Ireland', currency: 'EUR' },
  // { value: 'il', label: 'מְדִינַת יִשְׂרָאֵל', currency: 'ILS' },
  // { value: 'it', label: 'Italia', currency: 'EUR' },
  // { value: 'is', label: 'Iceland', currency: 'ISK' },
  // { value: 'jp', label: '日本', currency: 'JPY' },
  // { value: 'lt', label: 'Lietuva', currency: 'LTL' },
  // { value: 'lv', label: 'Latvija', currency: 'LVL' },
  // { value: 'my', label: 'Malaysia', currency: 'MYR' },
  // { value: 'mx', label: 'México', currency: 'MXN' },
  // { value: 'nl', label: 'Nederland', currency: 'EUR' },
  // { value: 'nz', label: 'New Zealand', currency: 'NZD' },
  // { value: 'ni', label: 'Nicaragua', currency: 'NIO' },
  // { value: 'no', label: 'Norge', currency: 'NOK' },
  // { value: 'pa', label: 'Panama', currency: 'PAB' },
  // { value: 'py', label: 'Paraguay', currency: 'PYG' },
  // { value: 'pe', label: 'Perú', currency: 'PEN' },
  // { value: 'ph', label: 'Philippines', currency: 'PHP' },
  // { value: 'pl', label: 'Polska IPA', currency: 'PLN' },
  // { value: 'pt', label: 'Portugal', currency: 'EUR' },
  // { value: 'pr', label: 'Puerto Rico', currency: 'USD' },
  // { value: 'kr', label: 'Republic of Korea', currency: 'KRW' },
  // { value: 'ro', label: 'România', currency: 'RON' },
  // { value: 'ru', label: 'Россия', currency: 'RUB' },
  // { value: 'sa', label: 'المملكة العربية السعودية', currency: 'SAR' },
  // { value: 'rs', label: 'Република Србија', currency: 'RSD' },
  // { value: 'sg', label: 'Singapore', currency: 'SGD' },
  // { value: 'za', label: 'South Africa', currency: 'ZAR' },
  // { value: 'es', label: 'España', currency: 'EUR' },
  // { value: 'se', label: 'Sverige', currency: 'SEK' },
  // { value: 'ch', label: 'Schweiz', currency: 'CHF' },
  // { value: 'tw', label: '臺灣', currency: 'TWD' },
  // { value: 'th', label: 'ประเทศไทย', currency: 'THB' },
  // { value: 'tr', label: 'Türkiye', currency: 'TRY' },
  // { value: 'ae', label: 'دولة الإمارات العربية المتحدة', currency: 'AED' },
  // { value: 'ua', label: 'Україна', currency: 'UAH' },
  // { value: 'gb', label: 'United Kingdom', currency: 'GBP' },
  // { value: 'en', label: 'United States', currency: 'USD' },
  // { value: 'uy', label: 'Uruguay', currency: 'UYU' },
  // { value: 've', label: 'Venezuela', currency: 'VEF' },
  // { value: 'vn', label: 'Việt Nam', currency: 'VND' }
];
export default langs;
