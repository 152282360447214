import React, { Component } from 'react';
import SearchBar from './SearchBar';
import moment from 'moment';
import { Col, Panel, Grid, Container, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, transit } from 'react-css-transition';
// import axios from 'axios';
let background = Math.floor(Math.random() * 9) + 1;

// controling the header image
// when to show an image and which searchbar type to show
// depending on the page
class HeaderImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSearch: false
    };
    this.modifySearch = this.modifySearch.bind(this);
    // this.getEnglishName = this.getEnglishName.bind(this);
  }
  // getEnglishName(city) {
  //   axios
  //     .post('https://email.hotelshop.com/googleplacestextquery', {
  //       inputQuery: city,
  //       language: 'en'
  //     })
  //     .catch(err => {
  //       this.props.appActions.setCityEn(city);
  //     })
  //     .then(response => {
  //       if (response && response.status === 200) {
  //         let res1 = JSON.parse(response.data);
  //         if (res1.status === 'OK') {
  //           this.props.appActions.setCityEn(
  //             res1.candidates[0].formatted_address
  //           );
  //         } else {
  //           this.props.appActions.setCityEn(city);
  //         }
  //       }
  //     });
  // }
  modifySearch() {
    // if (modify) {
    //   this.getEnglishName(this.props.searchInfo.city);
    //   this.props.appActions.setLatLng(
    //     this.props.searchInfo.lat,
    //     this.props.searchInfo.lng,
    //     this.props.cityLocal
    //   );
    // } else {
    //   this.props.appActions.setCityEn('');
    //   this.props.appActions.setLatLng(0, 0, '');
    // }
    this.setState({ editSearch: !this.state.editSearch });
  }
  render() {
    return (
      <div>
        {this.props.image !== 'no' && (
          <div className="margin-b20px">
            {this.props.image !== 'aboutPage' && (
              <div
                className={
                  this.props.image !== 'large'
                    ? this.props.loading === 'main'
                      ? 'imgBckHomeResults notLoading bg' + background
                      : 'imgBckHomeResults bg' + background
                    : this.props.loading === 'main'
                    ? 'imgBckHome notLoading bg' + background
                    : 'imgBckHome bg' + background
                }
              >
                {this.props.image === 'info' &&
                  this.props.cityLocal &&
                  this.props.searchInfo.lat.toString() ===
                    this.props.latUrl && (
                    <div className="txt-header-holder">
                      <div className="container text-left">
                        <Col className="text-header">
                          <p className="txt-white text-info-large">
                            {this.props.cityLocal}
                          </p>
                          <h4 className="txt-white margin-b50">
                            <span>
                              <span className="font-large">
                                {moment(this.props.searchInfo.start).format(
                                  'D'
                                )}
                              </span>
                              &nbsp;
                              {moment(this.props.searchInfo.start).format(
                                'MMM'
                              )}
                            </span>
                            {' - '}
                            <span>
                              <span className="font-large">
                                {moment(this.props.searchInfo.end).format('D')}
                              </span>
                              &nbsp;
                              {moment(this.props.searchInfo.end).format('MMM')}
                            </span>
                          </h4>
                        </Col>
                      </div>
                    </div>
                  )}
                {this.props.image === 'city' && (
                  <div className="txt-header-holder">
                    <div className="container text-left">
                      <Col className="text-header padding-t50">
                        <p className="txt-white text-info-large text-shadow-1">
                          {this.props.t('ALWAYS_BEST_DEALS')}
                        </p>
                      </Col>
                    </div>
                  </div>
                )}
                {this.props.image === 'large' && (
                  <div className="txt-home-holder">
                    <div className="container text-left">
                      <Col className="text-header">
                        <p className="txt-white text-info-large text-shadow-1">
                          {this.props.t('ALWAYS_BEST_DEALS')}
                        </p>
                        {this.props.bar !== 'no' && (
                          <Col
                            xs={12}
                            key={this.props.searchTime.toString()}
                            className="searchbar-wrapper padding0"
                          >
                            <SearchBar {...this.props} />
                            <div className="clear-all" />
                          </Col>
                        )}
                      </Col>
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.props.image === 'aboutPage' && (
              <div className="about-page-header txt-home-holder">
                <div className="maxw600 text-info-large txt-white padding-lr15 margin-lr-auto">
                  We are here to assist you wherever your next destination might
                  be!
                </div>
              </div>
            )}
          </div>
        )}
        {(this.props.image === 'info' || this.props.image === 'city') && (
          <div>
            <Container className="button-pull-up position-rel">
              <button
                className="button-edit-search"
                onClick={() => this.modifySearch()}
              >
                <span className="text-lowercase margin-r10">
                  {this.props.image === 'info'
                    ? this.props.t('EDIT_SEARCH')
                    : this.props.t('NEW_SEARCH')}
                </span>
                {/* {!this.state.editSearch ? (
                  <Glyphicon glyph="menu-down" />
                ) : (
                  <Glyphicon glyph="menu-up" />
                )} */}
                {!this.state.editSearch ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronUp} />
                )}
              </button>
            </Container>
            <CSSTransition
              active={this.state.editSearch}
              defaultStyle={{ opacity: 0 }}
              enterStyle={{ opacity: transit(1.0, 200, 'ease-in', 200) }}
              leaveStyle={{ opacity: transit(0, 200, 'ease-in') }}
              activeStyle={{ opacity: 1.0 }}
              transitionAppear
            >
              {this.state.editSearch && (
                <div className="panel-pull-up">
                  <div className="height-20" />
                  {/* <Accordion
                  className="panel-edit-search"
                  id="collapsible-panel-edit"
                >
                  <Accordion.Toggle eventKey="0">
                    ClickMe
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='0'> */}
                  <Container
                    className="padding-t20"
                    key={this.props.searchTime.toString()}
                  >
                    <SearchBar {...this.props} />
                  </Container>
                  {/* </Accordion.Collapse>
                </Accordion> */}
                  {/* <Panel
                  className="panel-edit-search"
                  id="collapsible-panel-edit"
                  expanded={this.state.editSearch}
                  onToggle={() => {}}
                >
                  <Panel.Collapse>
                    <Panel.Body>
                      <Container
                        className="padding-t20"
                        key={this.props.searchTime.toString()}
                      >
                        <SearchBar {...this.props} />
                      </Container>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel> */}
                </div>
              )}
            </CSSTransition>
          </div>
        )}
      </div>
    );
  }
}

export default HeaderImage;
