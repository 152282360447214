import React, { Component } from 'react';
// import './App.css';
import './styles/bootstrap/css/bootstrap-social.min.css';
// import './styles/bootstrap/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/bootstrap.css';
import './styles/home.css';
import './styles/style.css';
import './styles/header.css';
import './styles/footer.css';
import 'animate.css/animate.min.css';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as appActions from './actions/appActions';
import RoutesRender from './components/RoutesRender';
import { translate, Trans } from 'react-i18next';
import SubscribeFixed from './components/SubscribeFixed';
// import { totalAdultsChildren } from './common/utils';
import langs from './common/langs';
// import axios from 'axios';
import moment from 'moment';
import 'default-passive-events';
import { library } from '@fortawesome/fontawesome-svg-core';
import { G_A_KEY } from './common/const';
import {
  faWifi,
  faUtensils,
  faCoffee,
  faDumbbell,
  faSwimmingPool,
  faConciergeBell,
  faSmokingBan,
  faMale,
  faChild
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
  faPinterestSquare,
  faGooglePlusSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
// import { componentDidUpdate } from 'react-google-maps/lib/utils/MapChildHelper';
library.add(
  faWifi,
  faUtensils,
  faCoffee,
  faDumbbell,
  faSwimmingPool,
  faConciergeBell,
  faSmokingBan,
  faMale,
  faChild
);
library.add(
  faFacebookSquare,
  faTwitterSquare,
  faPinterestSquare,
  faGooglePlusSquare,
  faLinkedin
);

class App extends Component {
  constructor(props) {
    super(props);
    this.script = document.createElement('script');
    this.manageSub = this.manageSub.bind(this);
    this.scriptDeskPro = document.createElement('script');
    this.loadDeskPro = this.loadDeskPro.bind(this);
  }

  manageSub() {
    if (this.props.subStatus === 'show') {
      this.props.appActions.setSubStatus('hiddenTemp');
    } else if (this.props.subStatus !== 'hidden') {
      this.props.appActions.setSubStatus('show');
    }
  }
  loadDeskPro() {
    window.DESKPRO_WIDGET_OPTIONS = {
      helpdeskUrl: 'https://support.hotelshop.com/'
    };
    this.scriptDeskPro.src =
      'https://support.hotelshop.com/dyn-assets/pub/build/widget_loader.min.js';
    this.scriptDeskPro.id = 'dp-widget-loader';
    document.body.appendChild(this.scriptDeskPro);
  }
  componentDidMount() {
    // if (this.props.eneterSite && !this.props.enterSite.status)
    //   this.props.appActions.setEnterSiteTime({
    //     status: true,
    //     timeStamp: moment()
    //   });

    if (this.props.subStatus !== 'hidden')
      this.props.appActions.setSubStatus('start');
    setTimeout(() => this.manageSub(), 25 * 1000);
    setTimeout(() => this.manageSub(), 45 * 1000);
    //setTimeout(() => this.loadDeskPro(), 12 * 1000);
  }
  componentWillMount() {
    if (!this.props.country || !this.props.curr || !this.props.lang)
      this.props.appActions.cleanStoreLocal();
    if (
      this.props.searchTime &&
      moment(this.props.searchTime)
        .add(45, 'minutes')
        .diff(moment(), 'minutes') < 0
    ) {
      this.props.appActions.cleanStore();
    }
    let tempFilters = [
      { msg: '' },
      { name: '' },
      { stars: [] },
      { rating: -1 },
      { facilities: [] },
      { price: [0, 0], priceSlider: [0, 0] },
      { type: [] },
      { offer: false, hasOffers: 0 }
    ];
    this.props.appActions.setFilters(tempFilters);
    this.props.appActions.setGMapLoaded(false);
    this.script.src = this.props.lang
      ? 'https://maps.googleapis.com/maps/api/js?key=' +
        G_A_KEY +
        '&libraries=places&language=' +
        this.props.lang.value
      : 'https://maps.googleapis.com/maps/api/js?key=' +
        G_A_KEY +
        '&libraries=places&language=en';
    this.script.async = false;
    this.script.id = 'googleMaps';
    document.body.appendChild(this.script);
    this.script.onload = () => {
      this.props.appActions.setGMapLoaded(true);
    };
    // IF LANGUAGE NOT SET YET
    const { t, i18n } = this.props;
    if (typeof this.props.lang === 'undefined' || this.props.lang === null) {
      let selectedLang = langs.filter(function(obj) {
        return obj.value === 'en';
      });
      this.props.appActions.changeLang(selectedLang[0]);
      i18n.changeLanguage('en');
    }
    // IF DATE TIME NOT OK
    if (
      moment(this.props.exit).diff(moment(this.props.start)) <= 0 ||
      moment(this.props.start).diff(moment()) < 0
    ) {
      this.props.appActions.setDate(
        moment()
          .add(5, 'days')
          .format(),
        'start'
      );
      this.props.appActions.setDate(
        moment()
          .add(7, 'days')
          .format(),
        'end'
      );
    }
  }
  // IF TypeError (undefined or similar) CLEAN AND REDIRECT TO HOME
  // componentDidCatch(error) {
  //   if (error.name === 'TypeError') {
  //     this.props.appActions.cleanStore();
  //     setTimeout(
  //       window.open(
  //         window.location.protocol + '//' + window.location.hostname + '/',
  //         '_self'
  //       ),
  //       300
  //     );
  //   }
  // }

  render() {
    // const changeLanguage = lng => {
    //   i18n.changeLanguage(lng);
    // };
    return (
      <div className="App">
        <div>
          <SubscribeFixed {...this.props} />
          <RoutesRender {...this.props} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    rooms: state.appRed.rooms,
    showRooms: state.appRed.showRooms,
    activeRoom: state.appRed.activeRoom,
    showModalCal: state.appRed.showModalCal,
    toggleCal: state.appRed.toggleCal,
    url: state.appRed.url,
    userInfo: state.appRed.userInfo,
    bookInfo: state.appRed.bookInfo,
    searchTime: state.appRed.searchTime,
    subStatus: state.appRed.subStatus,
    filterModal: state.appRed.filterModal,
    filterMap: state.appRed.filterMap,
    routes: state.appRed.routes,
    modal: state.appRed.modal,
    msg: state.appRed.msg,
    error: state.appRed.error,
    page: state.appRed.page,
    loading: state.appRed.loading,
    homeSelCity: state.appRed.homeSelCity,
    order: state.appRed.order,
    start: state.appRed.start,
    end: state.appRed.end,
    hotelsMap: state.appRed.hotelsMap,
    lat: state.appRed.lat,
    lng: state.appRed.lng,
    cookies: state.appRed.cookies,
    exchangeRate: state.appRed.exchangeRate,
    city: state.appRed.city,
    cityEn: state.appRed.cityEn,
    cityLocal: state.appRed.cityLocal,
    cityHome: state.appRed.cityHome,
    hotelInfo: state.appRed.hotelInfo,
    searchInfo: state.appRed.searchInfo,
    priceValidation: state.appRed.priceValidation,
    packSelected: state.appRed.packSelected,
    guests: state.appRed.guests,
    guestsContact: state.appRed.guestsContact,
    valid: state.appRed.valid,
    complete: state.appRed.complete,
    visited: state.appRed.visited,
    favs: state.appRed.favs,
    filters: state.appRed.filters,
    hotelSelected: state.appRed.hotelSelected,
    citiesList: state.appRed.citiesList,
    hotelsHome: state.appRed.hotelsHome,
    gMapLoaded: state.appRed.gMapLoaded,
    mapInfo: state.appRed.mapInfo,
    mapInfoHover: state.appRed.mapInfoHover,
    completed: state.appRed.completed,
    tripAdvisor: state.appRed.tripAdvisor,
    redirectUrl: state.appRed.redirectUrl,
    hotels: state.appRed.hotels,
    filtered: state.appRed.filtered,
    lang: state.appRedLoc.lang,
    curr: state.appRedLoc.curr,
    radius: state.appRed.radius,
    country: state.appRedLoc.country,
    locVisited: state.appRedLoc.locVisited,
    showLocal: state.appRedLoc.showLocal,
    storeReservation: state.appRedOrder.storeReservation,
    confirmationInfo: state.appRedOrder.confirmationInfo,
    godMode: state.appRed.godMode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default compose(
  translate('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
