import React from 'react';
import { geolocated } from 'react-geolocated';
import platform from 'platform';
import ip from 'ip';
import axios from 'axios';
import locale from 'browser-locale';

class UserInfo extends React.Component {
  componentDidMount() {
    axios.get(`https://api.ipify.org`).then(res => {
      let clientIp = res.data;
      this.props.appActions.setUserInfo({ ipPublic: res.data });
      axios
        .get(`/data/ipGod.json`)
        .then(res => {
          if (res.data.indexOf(clientIp) >= 0)
            this.props.appActions.setGodMode(true);
          else this.props.appActions.setGodMode(false);
        })
        .catch(err => {
          this.props.appActions.setGodMode(false);
        });
    });
  }
  render() {
    let l = locale;
    let pl = platform;
    let i = ip.address('private', 'ipv4');
    if (
      this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords !== null &&
      this.props.userInfo.location !== this.props.coords
    ) {
      this.props.appActions.setUserInfo({ location: this.props.coords });
    }
    if (
      typeof i !== 'undefined' &&
      i !== null &&
      this.props.userInfo.ipPrivate !== i
    ) {
      this.props.appActions.setUserInfo({ ipPrivate: i });
    }
    if (
      typeof pl !== 'undefined' &&
      pl !== null &&
      this.props.userInfo.browser !== pl
    ) {
      this.props.appActions.setUserInfo({ browser: pl });
    }
    if (
      typeof l !== 'undefined' &&
      l !== null &&
      this.props.userInfo.locale !== l
    ) {
      this.props.appActions.setUserInfo({ locale: l });
    }
    return null;
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(UserInfo);
