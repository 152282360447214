import React, { Component } from 'react';
// import SearchBar from './SearchBar';
import Error from './Error';
// import moment from 'moment';
import 'moment/min/locales.min';
import { Link } from 'react-router-dom';
// import 'animate.css/animate.min.css';
import { Navbar, Nav, Image, NavItem } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import UserInfo from './UserInfo';
import Cooki from './Cooki';
import Facebook from './SocialButton';
import TopBar from './TopBar';
// import Languages from './Languages';
import ModalInfo from './ModalInfo';
import disableScroll from 'disable-scroll';
// import { totalAdultsChildren } from '../common/utils';
// import { SEARCH_RADIUS } from '../common/const';
// let background = Math.floor(Math.random() * 9) + 1;

class Header extends Component {
  constructor(props) {
    super(props);
    this.cleanStore = this.cleanStore.bind(this);
    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
    this.userFacebook = this.userFacebook.bind(this);
    this.clickHotels = this.clickHotels.bind(this);
  }
  handleSocialLogin = user => {
    if (this.props.userInfo.facebook !== user) {
      this.props.appActions.setUserInfo({ facebook: user });
    }
  };

  handleSocialLoginFailure = err => {
    console.error(err);
  };
  cleanStore(e) {
    e.preventDefault();
    this.props.appActions.cleanStore();
    this.props.appActions.setRouteInfo('home');
  }
  clickHotels() {
    this.props.appActions.setModalInfo('message1');
  }
  userFacebook() {
    let user = undefined;
    if (this.props.userInfo.facebook !== user) {
      return (
        <span>
          <Image
            className="facebookProfilePic"
            src={this.props.userInfo.facebook._profile.profilePicURL}
            rounded
          />
          {this.props.userInfo.facebook._profile.name}
        </span>
      );
    } else {
      return (
        <Facebook
          provider="facebook"
          appId="482508365499752"
          onLoginSuccess={this.handleSocialLogin}
          onLoginFailure={this.handleSocialLoginFailure}
        />
      );
    }
  }

  render() {
    // console.log(this.props.userInfo)
    const { t } = this.props;
    let homeLink =
      this.props.lang.value === 'en' ? '/' : '/' + this.props.lang.value;
    let loadingDiv = '';
    this.props.loading === 'main' || this.props.loading === 'simple'
      ? (loadingDiv = (
          <div className="loading-holder">
            <div className="loading">
              <div className="loader1">...loading</div>
              <h3 className="txt-gray-blue text-center">
                {this.props.t('PLZWAIT')}
              </h3>
            </div>
          </div>
        ))
      : (loadingDiv = <div>{disableScroll.off()}</div>);

    return (
      <div>
        {this.props.modal !== 'message2' && <ModalInfo {...this.props} />}
        <Cooki {...this.props} />
        <UserInfo {...this.props} />
        {/* <div className="top-bar">
          FIXED BAR ON TOP
        </div> */}
        <TopBar {...this.props} />
        <Navbar
          id="navMenu"
          collapseOnSelect
          expand="lg"
          className="rad0 header-main width-100-pcent"
        >
          <Container>
            <Navbar.Brand className="p-2">
              <Link to={homeLink}>
                <img
                  alt="logo"
                  style={{ height: '43px', width: 'auto', marginTop: '-5px' }}
                  className="hslogo"
                  src="/images/logo.png"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="pt11">
              <Nav className="text-right">
                {this.props.searchInfo && this.props.searchInfo.lat === 0 ? (
                  <Nav.Link
                    eventKey={1}
                    // onClick={() => this.clickHotels()}
                    // className="margin-nav"
                  >
                    {/* {t('MENU_HOTELSUPER_UP')} */}
                  </Nav.Link>
                ) : this.props.searchInfo &&
                  this.props.searchInfo.searchUrl !== '/' ? (
                  <LinkContainer to={this.props.searchInfo.searchUrl}>
                    <Nav.Link eventKey={1} className="margin-t10">
                      {t('MENU_HOTELSUPER_UP')}
                    </Nav.Link>
                  </LinkContainer>
                ) : (
                  ''
                )}
                {/*<Nav.Link
                  eventKey={2}
                  href="https://www.sky-tours.com/"
                  className="margin-t10"
                  target="_blank"
                >
                  {t('MENU_FLIGHT_UP')}
                </Nav.Link>
                <Nav.Link
                  eventKey={4}
                  href="https://cars.sky-tours.com/"
                  className="margin-t10"
                  target="_blank"
                >
                  {t('MENU_CAR_UP')}
                </Nav.Link>
                <Nav.Link
                  eventKey={5}
                  href="http://blog.sky-tours.com/"
                  className="margin-t10"
                  target="_blank"
                >
                  {t('SKYTOURS_BLOG')}
                </Nav.Link>*/}
                {/* <NavItem
                  eventKey={5}
                  onClick={() =>
                    this.props.appActions.setRouteInfo('confirmation')
                  }
                >
                  Confirmation
                </NavItem> */}
                {/* <NavItem eventKey={6} onClick={this.cleanStore}>
                  reset
                </NavItem> */}
              </Nav>
              {/* <div className="pull-right">
                <Languages {...this.props} />
              </div> */}
              {/* <NavItem className=" rmLi">{this.userFacebook()}</NavItem> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {loadingDiv}
        <Error {...this.props} />
      </div>
    );
  }
}

export default Header;
