export const FETCH_DATA = 'FETCH_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const RECEIVE_TA_DATA = 'RECEIVE_TA_DATA';
export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const CLEAN = 'CLEAN';
export const CLEAN_LOCAL = 'CLEAN_LOCAL';
export const ORDER = 'ORDER';
export const SET_RADIUS = 'SET_RADIUS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER = 'FILTER';
export const FILTERS = 'FILTERS';
export const VISITED = 'VISITED';
export const FAVS = 'FAVS';
export const SEARCH_INFO = 'SEARCH_INFO';
export const G_MAP_LOADED = 'G_MAP_LOADED';
export const SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS';
export const SEARCH_TIME = 'SEARCH_TIME';
export const GUESTS_INFO = 'GUESTS_INFO';
export const GUESTS_CONTACT = 'GUESTS_CONTACT';
export const VALIDATION = 'VALIDATION';
export const IS_COMPLETE = 'IS_COMPLETE';
export const ROUTE_INFO = 'ROUTE_INFO';
export const MODAL_INFO = 'MODAL_INFO';
export const BOOK_INFO = 'BOOK_INFO';
export const SHOW_HOTELSMAP = 'SHOW_HOTELSMAP';
export const SHOW_MAP_INFO = 'SHOW_MAP_INFO';
export const SHOW_MAP_INFO_HOVER = 'SHOW_MAP_INFO_HOVER';
export const SHOW_BAR = 'SHOW_BAR';
export const ACTIVE_BAR = 'ACTIVE_BAR';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const ROOMS_INFO = 'ROOMS_INFO';
export const SHOW_ROOMS = 'SHOW_ROOMS';
export const FILTER_MODAL = 'FILTER_MODAL';
export const FILTER_MAP = 'FILTER_MAP';
export const MODAL_CALENDAR = 'MODAL_CALENDAR';
export const SET_COORDINATES = 'SET_COORDINATES';
export const SETUSERINFO = 'SETUSERINFO';
export const ACCEPTCOOKIES = 'ACCEPTCOOKIES';
export const SET_CITY = 'SET_CITY';
export const SET_CITY_EN = 'SET_CITY_EN';
export const SET_CITY_LOCAL = 'SET_CITY_LOCAL';
export const SET_CITY_HOME = 'SET_CITY_HOME';
export const HOTEL_INFO = 'HOTEL_INFO';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_CURR = 'CHANGE_CURR';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const SET_SHOW_LOCAL = 'SET_SHOW_LOCAL';
export const SET_LOC_VISITED = 'SET_LOC_VISITED';
export const EXCHANGE_RATE = 'EXCHANGE_RATE';
export const PRICE_VALIDATION = 'PRICE_VALIDATION';
export const STORE_RESERVATION = 'STORE_RESERVATION';
export const CONFIRMATION_INFO = 'CONFIRMATION_INFO';
export const PACK_SELECTED = 'PACK_SELECTED';
export const SELECT_HOTEL = 'SELECT_HOTEL';
export const CITIES_LIST = 'CITIES_LIST';
export const HOTELS_HOME = 'HOTELS_HOME';
export const COMPLETED = 'COMPLETED';
export const REDIRECT_URL = 'REDIRECT_URL';
export const SET_GOD_MODE = 'SET_GOD_MODE';
