import CookieBanner from 'react-cookie-banner';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
const styles = {
  banner: {
    position: 'fixed',
    bottom: '0',
    height: 'auto',
    minHeight: 30,
    paddingTop: 10,
    background:
      'rgba(52, 64, 81, 0.8) url(/images/cookie.png) 20px 50% no-repeat',
    backgroundSize: '30px 30px',
    backgroundColor: '',
    fontSize: '15px',
    fontWeight: 600,
    zIndex: 100000
  },
  button: {
    position: 'relative',
    border: '1px solid white',
    borderRadius: 4,
    width: 100,
    height: 32,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    top: 0,
    right: 0,
    left: 0,
    marginTop: 10,
    marginBottom: 10
  },
  message: {
    display: 'block',
    padding: '9px 20px',
    lineHeight: 1.3,
    textAlign: 'center',
    color: 'white',
    clear: 'both'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold'
  }
};

class Cooki extends Component {
  constructor(props) {
    super(props);
    this.onAccept = this.onAccept.bind(this);
  }
  onAccept() {
    this.props.appActions.acceptCookies(true);
  }
  render() {
    const { t, i18n } = this.props;
    if (!this.props.cookies) {
      cookie.remove('accepts-cookies');

      const message = t('COOKIES_POLICY_INFO');
      // let info = {
      //   msg: message,
      //   url: 'http://nocookielaw.com/'
      // };
      return (
        <div>
          <CookieBanner
            dismissOnScroll={false}
            accepted={'false'}
            link={
              <Link to={'/' + this.props.lang.value + '/info/cookies'}>
                - {t('HOTEL_SERACH_SELECT_HOTEL')}
              </Link>
            }
            buttonMessage={t('CLOSE_TEXT')}
            message={message}
            onAccept={this.onAccept}
            styles={styles}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}
export default Cooki;
