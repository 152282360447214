import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import langs from '../common/langs';
import currency from '../common/currency';
// import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import 'moment/min/locales.min';
import { totalAdultsChildren, createSearchInfo } from '../common/utils';
import NationailtySelect from './NationalitySelect';

let propsLang = {};
let currencySelected = {};
let change = false;
let changeLang = false;
let changeCurr = false;

// control languages and currencies
class Languages extends Component {
  constructor(props) {
    super(props);

    this.selectLang = this.selectLang.bind(this);
    this.selectCurrency = this.selectCurrency.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setRoute = this.setRoute.bind(this);
  }
  selectLang(e) {
    // console.log(item);
    // this.setState({ lang: item });
    let selected = {};
    langs.filter(function(obj) {
      if (obj.label === e.target.value) {
        selected = obj;
        selected['url'] = selected.value;
      }
    });
    this.props.appActions.changeLang(selected);
    this.props.appActions.setLatLng(0, 0, '');
    //if (!this.state.change || this.state.change == null) this.setState({ change: true });
    if (change === false) change = true;
  }
  selectCurrency(e) {
    e.preventDefault();
    let selected = {};
    currency.filter(function(obj) {
      if (obj.cc === e.target.value) {
        selected = obj;
        selected['url'] = selected.cc;
      }
    });
    let currencySelected = {
      value: selected.cc,
      name: selected.name,
      symbol: selected.symbol
    };
    // this.setState({ curr: currency });
    this.props.appActions.changeCurrency(currencySelected);
    this.props.appActions.setExchangeRate(currencySelected.value);
    if (change === false) change = true;
  }
  setRoute(loc) {
    let route = null;
    if (loc.includes('/search/')) {
      route = 'search';
    } else if (loc.includes('/hotel/')) {
      route = 'search';
    } else if (loc.includes('/city/')) {
      route = 'home';
    } else if (loc.includes('/promo/')) {
      route = 'home';
    } else if (loc.includes('/info/about')) {
      route = '/info/about';
    } else if (loc.includes('/info/terms')) {
      route = '/info/terms';
    } else if (loc.includes('/info/privacy')) {
      route = '/info/privacy';
    } else if (loc.includes('/info/cookies')) {
      route = '/info/cookies';
    } else if (loc.includes('/unsubscribe')) {
      route = '/unsubscribe';
    } else {
      route = 'home';
    }
    // console.log(route);
    return route;
  }
  toggleModal(i18n, modal) {
    let route = this.setRoute(window.location.pathname);
    if (!modal) {
      this.props.appActions.setExchangeRate(this.props.curr.value);
    }
    this.props.appActions.setShowLocal(!modal);
    if (change || !this.props.locVisited) {
      i18n.changeLanguage(this.props.lang.value);
      // this.props.appActions.changeCurrency(this.state.curr);
      // this.props.appActions.changeLang(this.state.lang);
      moment.locale(this.props.lang.value);
      change = false;
      if (!this.props.locVisited && route === 'search') {
      } else if (this.props.searchInfo.lat !== 0 && route === 'search') {
        let searchUrl =
          '/' +
          this.props.lang.value +
          '/search/' +
          encodeURIComponent(this.props.searchInfo.city) +
          // this.props.searchInfo.city.split(' ').join('_') +
          '&loc=' +
          this.props.searchInfo.lat +
          '&' +
          this.props.searchInfo.lng +
          '&from=' +
          this.props.searchInfo.start +
          '&to=' +
          this.props.searchInfo.end +
          '&rooms=' +
          totalAdultsChildren(this.props.searchInfo.rooms) +
          '&curr=' +
          this.props.curr.value +
          '&rad=' +
          this.props.radius +
          '&country=' +
          this.props.country.code;

        setTimeout(() => window.location.assign(searchUrl), 100);
        // this.props.appActions.fetchData(
        //   this.props.searchInfo.city,
        //   this.props.searchInfo.start,
        //   this.props.searchInfo.end,
        //   this.props.curr.value,
        //   this.props.searchInfo.lat,
        //   this.props.searchInfo.lng,
        //   this.props.lang.value,
        //   this.props.searchInfo.rooms.length,
        //   this.props.searchInfo.rooms,
        //   totalAdultsChildren(this.props.searchInfo.rooms),
        //   SEARCH_RADIUS,
        //   route,
        //   false,
        //   this.props.citiesList
        // );
      } else if (route === 'home' && change) {
        this.props.appActions.getSearchInfo(
          createSearchInfo(
            this.props.lang.value,
            this.props.curr.value,
            this.props.searchInfo
          )
        );

        setTimeout(
          () => window.location.assign('/' + this.props.lang.value),
          100
        );
      } else if (change) {
        this.props.appActions.getSearchInfo(
          createSearchInfo(
            this.props.lang.value,
            this.props.curr.value,
            this.props.searchInfo
          )
        );

        setTimeout(
          () => window.location.assign('/' + this.props.lang.value + route),
          100
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.locVisited &&
      !nextProps.showLocal &&
      this.props.showLocal !== nextProps.showLocal
    )
      this.props.appActions.setLocVisited(true);
  }

  render() {
    // console.log(this.props.exchangeRate);
    const { i18n } = this.props;
    let valLang = '';
    let valCurr = '';
    if (
      this.props.exchangeRate &&
      moment().diff(moment(this.props.exchangeRate.time), 'minutes') > 240
    ) {
      this.props.appActions.setExchangeRate(this.props.curr.value);
    }
    //if the language url changes
    if (this.props.lang && this.props.lang.url !== this.props.lang.value) {
      if (!changeLang) {
        let langUrl = this.props.lang.url;
        langs.filter(function(obj) {
          if (obj.value === langUrl) {
            propsLang = obj;
            propsLang['url'] = langUrl;
            changeLang = true;
            return;
          }
        });
      }
      if (changeLang) {
        i18n.changeLanguage(propsLang.value);
        this.props.appActions.changeLang(propsLang);
        this.props.appActions.getSearchInfo(
          createSearchInfo(
            propsLang.value,
            this.props.curr.value,
            this.props.searchInfo
          )
        );
      }
    } else changeLang = false;

    //if the currency url changes
    if (this.props.curr && this.props.curr.url !== this.props.curr.value) {
      if (!changeCurr) {
        let currUrl = this.props.curr.url;
        currency.filter(function(obj) {
          if (obj.cc === currUrl) {
            // console.log(obj);
            currencySelected = {
              value: currUrl,
              name: obj.name,
              symbol: obj.symbol,
              url: currUrl
            };
            changeCurr = true;
            return;
          }
        });
      }
      if (changeCurr) {
        this.props.appActions.changeCurrency(currencySelected);
        this.props.appActions.getSearchInfo(
          createSearchInfo(
            this.props.lang.value,
            currencySelected.value,
            this.props.searchInfo
          )
        );
      }
    } else changeCurr = false;

    if (typeof this.props.lang !== 'undefined' && this.props.lang !== null) {
      // const { t, i18n } = this.props;
      valLang = (
        <h3 className="padding-5 text-left">
          <div className="border-blue-left">
            <div>{this.props.t('CHOOSE_LANG')}</div>
            <Image
              src={'/images/flags/' + this.props.lang.value + '.gif'}
              className="flagMargin"
            />
            <span className="txt18 txt-gray-blue">{this.props.lang.name}</span>
            <div className="clear-all" />
          </div>
        </h3>
        // <div className="pull-left">
        //   <Image
        //     src={'/images/flags/' + this.props.lang.value + '.gif'}
        //     className="flagMargin"
        //   />
        //   <span>{this.props.lang.value}</span>
        // </div>
      );
      // let tempLang = this.props.lang.value;
      // if (this.props.lang.value !== i18n.language && !change) {
      //   langs.filter(function(obj) {
      //     if(obj.value === tempLang) i18n.changeLanguage(obj.value);
      //     return;
      //   });
      //   // this.setState({ lang: selectedLang[0] });
      //   // this.props.appActions.changeLang(selectedLang[0]);
      // }
      if (this.props.lang && moment.locale() !== this.props.lang.value) {
        moment.locale(this.props.lang.value);
      }
      if (this.props.curr) {
        valCurr = (
          <h3 className="padding-5 text-left">
            <div className="border-blue-left">
              <div>{this.props.t('CHOOSE_CURR')}</div>
              <span className="txt18 txt-gray-blue">
                {this.props.curr.value}
                {' - '}
                {this.props.curr.symbol}
              </span>
              <div className="clear-all" />
            </div>
          </h3>
        );
      }
      return (
        <div>
          {this.props.curr && this.props.lang && (
            <button
              className="margin-lr-0 button-helper"
              onClick={() => this.toggleModal(i18n, this.props.showLocal)}
            >
              <Image
                src={'/images/flags/' + this.props.lang.value + '.gif'}
                className="flagMargin"
              />
              {this.props.country && this.props.country.code}
              {' - '}
              {this.props.curr.symbol}
            </button>
          )}
          <Modal
            className="modalMsg2"
            show={this.props.showLocal}
            onHide={() => this.toggleModal(i18n, this.props.showLocal)}
          >
            <Modal.Body className="text-center">
              <Col xs={12}>
                {!this.props.locVisited && (
                  <h2>{this.props.t('SEARCH_CHECK')}</h2>
                )}
                <form>
                  <FormGroup controlId="formControlsSelectCountry">
                    <FormLabel className="float-left">
                      <div className="width-100 txt-blue">{valLang}</div>
                    </FormLabel>
                    <FormControl
                      as="select"
                      onChange={e => this.selectLang(e)}
                      value={this.props.lang.label}
                      className="input-sm"
                    >
                      {langs.map((item, i) => (
                        <option value={item.label} key={i}>
                          {item.value}
                          {' - '}
                          {item.hasOwnProperty('native')
                            ? item.native
                            : item.label}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>
                  <NationailtySelect {...this.props} />
                  <FormGroup controlId="formControlsSelectCurrency">
                    <FormLabel className="float-left">
                      <div className="width-100 txt-blue">{valCurr}</div>
                    </FormLabel>
                    <FormControl
                      as="select"
                      onChange={e => this.selectCurrency(e)}
                      value={this.props.curr.value}
                      className="input-sm"
                    >
                      {currency.map((item, i) => (
                        <option
                          value={item.cc}
                          key={i}
                          disabled={item.cc === '- - -'}
                        >
                          {item.cc}
                          {' - '}
                          {item.name}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>
                </form>
                {/* <br />
                <div className="width-100 text-left padding-lr17px txt-blue">
                  {value}
                </div>
                <div
                  className="width-100 text-left dropLang back-gray-blue-light padding-lr20px"
                  id="Languages"
                >
                  {langs.map((item, i) => {
                    return (
                      <div key={'l' + i}>
                        <Row
                          className="padding-5"
                          onClick={e => {
                            this.selectLang(item, e);
                          }}
                        >
                          <div className="cursor-pointer">
                            <span className={'flag flag-' + item.value}>
                              &nbsp;
                            </span>
                            <span
                              className={
                                this.props.lang.value === item.value
                                  ? 'txt-blue'
                                  : ''
                              }
                            >
                              {item.label}
                            </span>
                          </div>
                        </Row>
                        {i === 3 && <hr className="hr2" />}
                      </div>
                    );
                  })}
                </div> */}
              </Col>
              {/* <Col xs={12} md={6}>
                <br />
                <div className="width-100 text-left padding-lr10px txt-blue">
                  {this.props.curr.value}
                  {' - '}
                  {this.props.curr.symbol}
                </div>
                <div
                  className="width-100 dropLang text-left back-gray-blue-light padding-lr20px"
                  id="Currencies"
                >
                  {currency.map((item, i) => {
                    return (
                      <div key={'k' + i}>
                        <Row
                          className="padding-5"
                          onClick={e => {
                            this.selectCurrency(item, e);
                          }}
                        >
                          <span
                            className={
                              this.props.curr.value === item.cc
                                ? 'txt-blue cursor-pointer'
                                : 'cursor-pointer'
                            }
                          >
                            {item.cc}
                            {' - '}
                            {item.name}
                          </span>
                        </Row>
                        {i === 3 && 
                        <div>
                          <hr className="hr2" />
                          <span>All Currencies</span>
                          <Glyphicon glyph="chevron-down"/>
                          <hr className="hr1" />
                        </div>}
                      </div>
                    );
                  })}
                </div>
              </Col> */}
              <div className="clear-both" />
              <br />
              <Col xs={12} className="margin-tb15">
                <button
                  className="padding-lr20px width-100"
                  onClick={() => this.toggleModal(i18n, this.props.showLocal)}
                >
                  {this.props.t('CLOSE_TEXT')}
                </button>
              </Col>
              <div className="clear-both" />
            </Modal.Body>
          </Modal>
        </div>
      );
    } else return null;
  }
}
export default Languages;
