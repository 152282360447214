import initialStateLocal from './initialStateLocal';

import {
  CLEAN_LOCAL,
  CHANGE_LANG,
  CHANGE_CURR,
  CHANGE_COUNTRY,
  SET_SHOW_LOCAL,
  SET_LOC_VISITED
} from '../actions/allActions';

export default function appRedLoc(state = initialStateLocal, action) {
  switch (action.type) {
    case CLEAN_LOCAL:
      return initialStateLocal;
    case CHANGE_LANG:
      return { ...state, lang: action.lang };
    case CHANGE_CURR:
      return { ...state, curr: action.curr };
    case CHANGE_COUNTRY:
      return { ...state, country: action.country };
    case SET_LOC_VISITED:
      return { ...state, locVisited: action.locVisited };
    case SET_SHOW_LOCAL:
      return { ...state, showLocal: action.showLocal };
    default:
      return state;
  }
}
