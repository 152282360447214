import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Subscribe from './Subscribe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class SubscribeFixed extends Component {
  render() {
    return (
      <div>
        {(this.props.subStatus === 'show' ||
          this.props.subStatus === 'working') && (
          <div className="subscription-holder animated fadeIn">
            <Container>
              <Row>
                <Col className="subscription-wrapper padding-tb15">
                  <Row className="text-left padding0">
                    <Col xs={10}>
                      <h3 className="padding0 margin-t0 txt-white">
                        Subscribe for the best hotel deals!
                      </h3>
                    </Col>
                    <Col
                      xs={2}
                      className="txt-white text-right cursor-pointer"
                      onClick={() => {
                        this.props.appActions.setSubStatus('hidden');
                      }}
                    >
                      {/* <Glyphicon
                        glyph="remove"
                        className="padding-5 margin-t-4"
                      /> */}
                      <FontAwesomeIcon icon={faTimes} className="" />
                    </Col>
                  </Row>
                  <div className="padding0">
                    <Subscribe {...this.props} />
                  </div>
                  <div className="clear-all" />
                  <Col className="d-block d-md-none height-40" />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

export default SubscribeFixed;
