import initialStateOrder from './initialStateOrder';

import { CONFIRMATION_INFO, STORE_RESERVATION } from '../actions/allActions';

export default function appRedOrder(state = initialStateOrder, action) {
  switch (action.type) {
    case CONFIRMATION_INFO:
      return { ...state, confirmationInfo: action.confirmationInfo };
    case STORE_RESERVATION:
      return { ...state, storeReservation: action.storeReservation };
    default:
      return state;
  }
}
